import React, { useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  useColorModeValue,
  Avatar,
  VStack,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { RiHeart3Line, RiHeart3Fill, RiChat1Line, RiShareLine } from 'react-icons/ri';

// Анимация плавающих аватаров
const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-10px) rotate(3deg); }
  50% { transform: translateY(0px) rotate(0deg); }
  75% { transform: translateY(10px) rotate(-3deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

// Типы связей между пользователями
interface Connection {
  from: number;
  to: number;
  type: 'subscription' | 'help';
}

// Интерфейс для действий пользователя
interface UserActions {
  likes: number;
  comments: number;
  isLiked: boolean;
  lastActive: string;
  bio: string;
}

// Расширенные данные о пользователях
interface User {
  id: number;
  name: string;
  avatar: string;
  rank: string;
  dreams: number;
  completedDreams: number;
  status: number; // От 1 до 5, влияет на размер аватара
  position?: { x: number; y: number }; // Позиция для отрисовки
  actions: UserActions;
}

// Физические параметры для симуляции
interface PhysicsNode {
  x: number;
  y: number;
  vx: number;
  vy: number;
  fx: number;
  fy: number;
  radius: number;
}

// Данные о связях между пользователями
const connections: Connection[] = [
  { from: 1, to: 2, type: 'subscription' },
  { from: 1, to: 3, type: 'help' },
  { from: 2, to: 4, type: 'subscription' },
  { from: 3, to: 5, type: 'help' },
  { from: 4, to: 6, type: 'subscription' },
  { from: 5, to: 1, type: 'subscription' },
  { from: 6, to: 2, type: 'help' },
  { from: 7, to: 1, type: 'subscription' },
  // Добавляем новые связи
  { from: 13, to: 14, type: 'help' },
  { from: 14, to: 15, type: 'subscription' },
  { from: 15, to: 16, type: 'help' },
  { from: 16, to: 13, type: 'subscription' },
  { from: 17, to: 13, type: 'help' },
  { from: 18, to: 15, type: 'subscription' },
  { from: 13, to: 1, type: 'help' },
  { from: 15, to: 3, type: 'subscription' }
];

// Обновленные данные о пользователях
const users: User[] = [
  {
    id: 1,
    name: 'Анна П.',
    avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=100&h=100&fit=crop',
    rank: '🎯 Достигатор целей',
    dreams: 12,
    completedDreams: 5,
    status: 4,
    actions: {
      likes: 234,
      comments: 18,
      isLiked: false,
      lastActive: '2024-04-20',
      bio: 'Мечтаю о путешествиях и новых открытиях ✈️ | Помогаю другим достигать целей 🎯',
    },
  },
  {
    id: 2,
    name: 'Михаил И.',
    avatar: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?w=100&h=100&fit=crop',
    rank: '💫 Искатель возможностей',
    dreams: 8,
    completedDreams: 3,
    status: 3,
    actions: {
      likes: 156,
      comments: 12,
      isLiked: true,
      lastActive: '2024-04-19',
      bio: 'Создаю технологичные решения для лучшего будущего 🚀 | Программист',
    },
  },
  {
    id: 3,
    name: 'Елена С.',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
    rank: '🌟 Вдохновитель',
    dreams: 15,
    completedDreams: 7,
    status: 5,
    actions: {
      likes: 428,
      comments: 45,
      isLiked: false,
      lastActive: '2024-04-20',
      bio: 'Помогаю людям раскрыть свой потенциал ✨ | Коуч | Мотиватор',
    },
  },
  {
    id: 4,
    name: 'Дмитрий К.',
    avatar: 'https://images.unsplash.com/photo-1599566150163-29194dcaad36?w=100&h=100&fit=crop',
    rank: '🚀 Мечтатель',
    dreams: 6,
    completedDreams: 2,
    status: 2,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-03-10',
      bio: 'Люблю путешествовать и исследовать новые места',
    },
  },
  {
    id: 5,
    name: 'Мария В.',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
    rank: '💎 Создатель реальности',
    dreams: 20,
    completedDreams: 12,
    status: 5,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-04-20',
      bio: 'Люблю создавать и развивать проекты',
    },
  },
  {
    id: 6,
    name: 'Александр Н.',
    avatar: 'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?w=100&h=100&fit=crop',
    rank: '🔮 Архитектор мечт',
    dreams: 10,
    completedDreams: 4,
    status: 3,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-03-30',
      bio: 'Люблю создавать и проектировать',
    },
  },
  {
    id: 7,
    name: 'Ольга М.',
    avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&h=100&fit=crop',
    rank: '🎯 Достигатор целей',
    dreams: 9,
    completedDreams: 3,
    status: 4,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-04-05',
      bio: 'Люблю путешествовать и исследовать новые места',
    },
  },
  {
    id: 8,
    name: 'Игорь С.',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
    rank: '✨ Новый мечтатель',
    dreams: 2,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-02-15',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 9,
    name: 'Татьяна К.',
    avatar: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?w=100&h=100&fit=crop',
    rank: '🌱 Начинающий',
    dreams: 1,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-01',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 10,
    name: 'Сергей В.',
    avatar: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=100&h=100&fit=crop',
    rank: '🎭 Искатель пути',
    dreams: 3,
    completedDreams: 0,
    status: 2,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-02-20',
      bio: 'Люблю путешествовать и исследовать новые места',
    },
  },
  {
    id: 11,
    name: 'Наталья Д.',
    avatar: 'https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?w=100&h=100&fit=crop',
    rank: '🍀 Новичок',
    dreams: 1,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-10',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 12,
    name: 'Павел Р.',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&h=100&fit=crop',
    rank: '🌟 Начинающий мечтатель',
    dreams: 2,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-20',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  // Добавляем новых пользователей
  {
    id: 13,
    name: 'Екатерина М.',
    avatar: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=100&h=100&fit=crop',
    rank: '🌟 Вдохновитель',
    dreams: 14,
    completedDreams: 6,
    status: 4,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-03-15',
      bio: 'Люблю создавать и развивать проекты',
    },
  },
  {
    id: 14,
    name: 'Артём В.',
    avatar: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?w=100&h=100&fit=crop',
    rank: '💫 Искатель возможностей',
    dreams: 9,
    completedDreams: 4,
    status: 3,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-03-20',
      bio: 'Люблю исследовать новые технологии',
    },
  },
  {
    id: 15,
    name: 'София К.',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
    rank: '💎 Создатель реальности',
    dreams: 18,
    completedDreams: 8,
    status: 5,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-04-10',
      bio: 'Люблю создавать и развивать проекты',
    },
  },
  {
    id: 16,
    name: 'Максим Д.',
    avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=100&h=100&fit=crop',
    rank: '🔮 Архитектор мечт',
    dreams: 11,
    completedDreams: 5,
    status: 4,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-03-30',
      bio: 'Люблю создавать и проектировать',
    },
  },
  {
    id: 17,
    name: 'Алиса П.',
    avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&h=100&fit=crop',
    rank: '🎯 Достигатор целей',
    dreams: 8,
    completedDreams: 3,
    status: 3,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-03-15',
      bio: 'Люблю путешествовать и исследовать новые места',
    },
  },
  {
    id: 18,
    name: 'Роман С.',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
    rank: '🚀 Мечтатель',
    dreams: 7,
    completedDreams: 2,
    status: 3,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-02-20',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 19,
    name: 'Ирина Л.',
    avatar: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?w=100&h=100&fit=crop',
    rank: '✨ Новый мечтатель',
    dreams: 3,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-10',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 20,
    name: 'Владимир К.',
    avatar: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=100&h=100&fit=crop',
    rank: '🌱 Начинающий',
    dreams: 2,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-01',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 21,
    name: 'Анастасия Р.',
    avatar: 'https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?w=100&h=100&fit=crop',
    rank: '🎭 Искатель пути',
    dreams: 4,
    completedDreams: 1,
    status: 2,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-02-10',
      bio: 'Люблю путешествовать и исследовать новые места',
    },
  },
  {
    id: 22,
    name: 'Григорий М.',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&h=100&fit=crop',
    rank: '🍀 Новичок',
    dreams: 1,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-05',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 23,
    name: 'Юлия Н.',
    avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=100&h=100&fit=crop',
    rank: '🌟 Начинающий мечтатель',
    dreams: 2,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-15',
      bio: 'Новый в сообществе мечтателей',
    },
  },
  {
    id: 24,
    name: 'Денис А.',
    avatar: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?w=100&h=100&fit=crop',
    rank: '✨ Новый мечтатель',
    dreams: 1,
    completedDreams: 0,
    status: 1,
    actions: {
      likes: 0,
      comments: 0,
      isLiked: false,
      lastActive: '2023-01-01',
      bio: 'Новый в сообществе мечтателей',
    },
  }
];

const Community = () => {
  const bgGradient = useColorModeValue(
    'linear(to-b, brand.50, white)',
    'linear(to-b, gray.900, gray.800)'
  );
  const cardBg = useColorModeValue('white', 'gray.800');
  const popoverBg = useColorModeValue('white', 'gray.800');
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const physicsRef = useRef<PhysicsNode[]>([]);
  const animationFrameRef = useRef<number>();

  // Инициализация физической симуляции
  const initPhysics = () => {
    const container = containerRef.current;
    if (!container) return;

    physicsRef.current = users.map(user => ({
      x: Math.random() * container.offsetWidth,
      y: Math.random() * container.offsetHeight,
      vx: (Math.random() - 0.5) * 2,
      vy: (Math.random() - 0.5) * 2,
      fx: 0,
      fy: 0,
      radius: parseInt(getAvatarSize(user.id)) / 2,
    }));
  };

  // Обновление физической симуляции
  const updatePhysics = () => {
    const container = containerRef.current;
    if (!container) return;

    const width = container.offsetWidth;
    const height = container.offsetHeight;
    const nodes = physicsRef.current;
    const dt = 0.016; // Временной шаг (≈60 fps)

    // Сброс сил
    nodes.forEach(node => {
      node.fx = 0;
      node.fy = 0;
    });

    // Центр притяжения для связанных узлов
    const centerX = width / 2;
    const centerY = height / 2;

    // Применение центральной силы для всех узлов
    nodes.forEach((node, i) => {
      const dx = centerX - node.x;
      const dy = centerY - node.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      // Слабая сила к центру для всех узлов
      node.fx += (dx / distance) * 0.1;
      node.fy += (dy / distance) * 0.1;
    });

    // Применение сил между связанными узлами
    connections.forEach(connection => {
      const fromNode = nodes[users.findIndex(u => u.id === connection.from)];
      const toNode = nodes[users.findIndex(u => u.id === connection.to)];
      
      const dx = toNode.x - fromNode.x;
      const dy = toNode.y - fromNode.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      // Увеличиваем силу притяжения
      const strength = connection.type === 'help' ? 1.2 : 0.8;
      const targetDistance = 120; // Уменьшаем желаемое расстояние
      
      const force = (distance - targetDistance) * strength;
      const fx = (dx / distance) * force;
      const fy = (dy / distance) * force;
      
      fromNode.fx += fx;
      fromNode.fy += fy;
      toNode.fx -= fx;
      toNode.fy -= fy;
    });

    // Обновление позиций и скоростей
    nodes.forEach((node, i) => {
      const userConnections = connections.filter(
        c => c.from === users[i].id || c.to === users[i].id
      );
      
      if (userConnections.length === 0) {
        // Орбитальное движение для несвязанных узлов
        const time = Date.now() / 1000;
        const angle = (i * Math.PI * 0.4) + time * 0.2;
        const orbitRadius = 250 + Math.sin(time * 0.5 + i) * 50;
        
        const targetX = centerX + Math.cos(angle) * orbitRadius;
        const targetY = centerY + Math.sin(angle) * orbitRadius;
        
        node.fx += (targetX - node.x) * 0.05;
        node.fy += (targetY - node.y) * 0.05;
      }

      // Отталкивание узлов друг от друга
      nodes.forEach((otherNode, j) => {
        if (i !== j) {
          const dx = node.x - otherNode.x;
          const dy = node.y - otherNode.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const minDistance = node.radius + otherNode.radius;
          
          if (distance < minDistance) {
            const force = (minDistance - distance) * 0.2;
            node.fx += (dx / distance) * force;
            node.fy += (dy / distance) * force;
          }
        }
      });

      // Ограничение максимальной скорости
      const maxSpeed = userConnections.length === 0 ? 3 : 4;
      node.vx = Math.max(-maxSpeed, Math.min(maxSpeed, node.vx + node.fx * dt));
      node.vy = Math.max(-maxSpeed, Math.min(maxSpeed, node.vy + node.fy * dt));

      // Обновление позиции
      node.x += node.vx;
      node.y += node.vy;

      // Отражение от границ с отступом
      const padding = 50;
      if (node.x < padding) { node.x = padding; node.vx *= -0.5; }
      if (node.x > width - padding) { node.x = width - padding; node.vx *= -0.5; }
      if (node.y < padding) { node.y = padding; node.vy *= -0.5; }
      if (node.y > height - padding) { node.y = height - padding; node.vy *= -0.5; }

      // Затухание скорости
      node.vx *= 0.98;
      node.vy *= 0.98;
    });

    // Обновление позиций пользователей
    users.forEach((user, i) => {
      user.position = {
        x: nodes[i].x,
        y: nodes[i].y,
      };
    });
  };

  // Функция анимации
  const animate = () => {
    updatePhysics();
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (ctx) {
      drawConnections(ctx);
    }
    animationFrameRef.current = requestAnimationFrame(animate);
  };

  // Функция обновления позиций пользователей
  const updatePositions = () => {
    const container = containerRef.current;
    const canvas = canvasRef.current;
    if (!container || !canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Установка размеров canvas
    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(canvas.width, canvas.height) * 0.35;

    // Обновление позиций пользователей
    users.forEach((user, index) => {
      const angle = (index / users.length) * Math.PI * 2;
      user.position = {
        x: centerX + Math.cos(angle) * radius,
        y: centerY + Math.sin(angle) * radius,
      };
    });

    // Отрисовка связей
    drawConnections(ctx);
  };

  // Функция для отрисовки связей
  const drawConnections = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    connections.forEach(connection => {
      const fromUser = users.find(u => u.id === connection.from);
      const toUser = users.find(u => u.id === connection.to);

      if (fromUser?.position && toUser?.position) {
        // Получаем размеры аватаров
        const fromSize = parseInt(getAvatarSize(fromUser.id)) / 2;
        const toSize = parseInt(getAvatarSize(toUser.id)) / 2;

        // Рисуем градиентную линию
        const gradient = ctx.createLinearGradient(
          fromUser.position.x,
          fromUser.position.y,
          toUser.position.x,
          toUser.position.y
        );

        if (connection.type === 'help') {
          gradient.addColorStop(0, 'rgba(255, 165, 0, 0.4)');
          gradient.addColorStop(1, 'rgba(255, 165, 0, 0.1)');
          ctx.lineWidth = 3;
        } else {
          gradient.addColorStop(0, 'rgba(100, 200, 255, 0.3)');
          gradient.addColorStop(1, 'rgba(100, 200, 255, 0.1)');
          ctx.lineWidth = 2;
        }

        // Рассчитываем направление линии
        const dx = toUser.position.x - fromUser.position.x;
        const dy = toUser.position.y - fromUser.position.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        // Нормализованные векторы направления
        const nx = dx / distance;
        const ny = dy / distance;

        // Начальная и конечная точки с учетом размеров аватаров
        const startX = fromUser.position.x;
        const startY = fromUser.position.y;
        const endX = toUser.position.x;
        const endY = toUser.position.y;

        // Рисуем линию
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = gradient;
        ctx.stroke();

        // Добавляем эффект свечения
        ctx.save();
        ctx.globalAlpha = 0.1;
        ctx.lineWidth = ctx.lineWidth * 2;
        ctx.stroke();
        ctx.restore();
      }
    });
  };

  // Обработчик изменения размера окна
  useEffect(() => {
    const handleResize = () => {
      const container = containerRef.current;
      const canvas = canvasRef.current;
      if (!container || !canvas) return;

      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
      
      // Переинициализация физики при изменении размера
      initPhysics();
    };

    // Инициализация
    handleResize();
    initPhysics();
    animate();

    // Добавляем обработчик изменения размера окна
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // Получение размера аватара на основе статуса и количества связей
  const getAvatarSize = (userId: number) => {
    const user = users.find(u => u.id === userId);
    const connectionCount = connections.filter(
      c => c.from === userId || c.to === userId
    ).length;
    
    const baseSize = user?.status || 3;
    const connectionBonus = Math.min(connectionCount * 0.5, 2);
    
    const sizes = {
      sm: '40px',
      md: '48px',
      lg: '56px',
      xl: '64px',
      '2xl': '72px',
    };

    const sizeIndex = Math.min(Math.floor(baseSize + connectionBonus), 5);
    return Object.values(sizes)[sizeIndex - 1];
  };

  // Получение цвета бордера на основе статуса
  const getBorderColor = (userId: number) => {
    const user = users.find(u => u.id === userId);
    const connectionCount = connections.filter(
      c => c.from === userId || c.to === userId
    ).length;
    
    const intensity = Math.min(((user?.status || 3) + connectionCount * 0.3) / 6, 1);
    return `rgba(14, 165, 233, ${intensity})`; // brand.500 с разной прозрачностью
  };

  // Функция для форматирования числа лайков
  const formatLikes = (likes: number): string => {
    if (likes >= 1000) {
      return `${(likes / 1000).toFixed(1)}K`;
    }
    return likes.toString();
  };

  // Функция для форматирования даты
  const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    
    if (days === 0) return 'сегодня';
    if (days === 1) return 'вчера';
    if (days < 7) return `${days} дн. назад`;
    if (days < 30) return `${Math.floor(days / 7)} нед. назад`;
    return `${Math.floor(days / 30)} мес. назад`;
  };

  return (
    <Box bgGradient={bgGradient} minH="100vh" py={20}>
      <Container maxW="container.xl">
        <VStack spacing={16}>
          {/* Плавающие аватары и связи */}
          <Box
            ref={containerRef}
            position="relative"
            w="full"
            h={{ base: "400px", md: "600px" }}
            overflow="hidden"
            bg="rgba(0, 0, 0, 0.03)"
            rounded="xl"
          >
            <canvas
              ref={canvasRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
            {users.map((user) => (
              <Box
                key={user.id}
                position="absolute"
                left={user.position?.x ? `${user.position.x}px` : '0'}
                top={user.position?.y ? `${user.position.y}px` : '0'}
                transform="translate(-50%, -50%)"
                role="group"
                zIndex={1}
                style={{
                  transition: 'none'
                }}
              >
                <Box 
                  position="relative"
                  animation={`${float} ${4 + Math.random() * 2}s ease-in-out infinite`}
                >
                  <Avatar
                    size="xl"
                    name={user.name}
                    src={user.avatar}
                    border="4px solid"
                    borderColor={getBorderColor(user.id)}
                    width={getAvatarSize(user.id)}
                    height={getAvatarSize(user.id)}
                    transition="all 0.3s"
                    _groupHover={{
                      transform: 'scale(1.2)',
                      zIndex: 3,
                    }}
                  />
                  <Box
                    position="absolute"
                    left="50%"
                    transform="translateX(-50%) translateY(10px)"
                    top="100%"
                    bg={popoverBg}
                    p={0}
                    rounded="xl"
                    shadow="lg"
                    minW="300px"
                    opacity={0}
                    visibility="hidden"
                    transition="all 0.3s"
                    _groupHover={{
                      opacity: 1,
                      visibility: "visible",
                      transform: "translateX(-50%) translateY(0)",
                      zIndex: 3,
                    }}
                  >
                    {/* Хедер карточки */}
                    <HStack p={4} borderBottom="1px solid" borderColor="gray.100">
                      <Avatar
                        size="sm"
                        name={user.name}
                        src={user.avatar}
                      />
                      <VStack align="start" spacing={0} flex={1}>
                        <Text fontWeight="bold" fontSize="sm">{user.name}</Text>
                        <Text fontSize="xs" color="gray.500">{formatDate(user.actions.lastActive)}</Text>
                      </VStack>
                    </HStack>

                    {/* Информация о пользователе */}
                    <VStack p={4} spacing={2} align="start">
                      <Text fontSize="sm" fontWeight="medium">{user.rank}</Text>
                      <Text fontSize="sm">{user.actions.bio}</Text>
                      <HStack spacing={4} fontSize="sm" color="gray.600">
                        <Text>Мечт: {user.dreams}</Text>
                        <Text>Исполнено: {user.completedDreams}</Text>
                      </HStack>
                    </VStack>

                    {/* Действия */}
                    <HStack p={4} borderTop="1px solid" borderColor="gray.100" spacing={4}>
                      <HStack spacing={2}>
                        <Icon
                          as={user.actions.isLiked ? RiHeart3Fill : RiHeart3Line}
                          w={6}
                          h={6}
                          color={user.actions.isLiked ? "red.500" : "gray.600"}
                          cursor="pointer"
                          transition="all 0.2s"
                          _hover={{ transform: "scale(1.1)" }}
                        />
                        <Text fontSize="sm" color="gray.600">
                          {formatLikes(user.actions.likes)}
                        </Text>
                      </HStack>
                      <HStack spacing={2}>
                        <Icon
                          as={RiChat1Line}
                          w={6}
                          h={6}
                          color="gray.600"
                          cursor="pointer"
                          transition="all 0.2s"
                          _hover={{ transform: "scale(1.1)" }}
                        />
                        <Text fontSize="sm" color="gray.600">
                          {user.actions.comments}
                        </Text>
                      </HStack>
                      <Icon
                        as={RiShareLine}
                        w={6}
                        h={6}
                        color="gray.600"
                        cursor="pointer"
                        transition="all 0.2s"
                        _hover={{ transform: "scale(1.1)" }}
                        ml="auto"
                      />
                    </HStack>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Заголовок и описание */}
          <VStack spacing={4} textAlign="center" pt={8}>
            <Heading
              as="h1"
              size="2xl"
              bgGradient="linear(to-r, brand.400, brand.600)"
              bgClip="text"
            >
              Сообщество мечтателей
            </Heading>
            <Text fontSize="xl" maxW="800px">
              Познакомьтесь с единомышленниками, которые уже воплощают свои мечты в реальность
            </Text>
          </VStack>

          {/* Статистика сообщества */}
          <StatGroup
            w="full"
            display="grid"
            gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={8}
            pb={8}
          >
            <Stat
              p={6}
              bg={cardBg}
              rounded="xl"
              shadow="lg"
              textAlign="center"
            >
              <StatLabel fontSize="lg">Активных мечтателей</StatLabel>
              <StatNumber fontSize="4xl" color="brand.500">12,458</StatNumber>
            </Stat>
            <Stat
              p={6}
              bg={cardBg}
              rounded="xl"
              shadow="lg"
              textAlign="center"
            >
              <StatLabel fontSize="lg">Всего мечт</StatLabel>
              <StatNumber fontSize="4xl" color="brand.500">45,789</StatNumber>
            </Stat>
            <Stat
              p={6}
              bg={cardBg}
              rounded="xl"
              shadow="lg"
              textAlign="center"
            >
              <StatLabel fontSize="lg">Исполнено мечт</StatLabel>
              <StatNumber fontSize="4xl" color="brand.500">8,932</StatNumber>
            </Stat>
          </StatGroup>
        </VStack>
      </Container>
    </Box>
  );
};

export default Community; 
import React from 'react';
import {
  Box,
  Button,
  VStack,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Heading,
  Text,
  Avatar,
  HStack,
  Icon,
  Textarea,
} from '@chakra-ui/react';
import { FaHeart, FaComment } from 'react-icons/fa';

// Временные данные для демонстрации
const mockInsights = [
  {
    id: 1,
    author: {
      name: 'Елена С.',
      avatar: 'https://source.unsplash.com/random/100x100/?woman'
    },
    content: 'Осознала, что счастье не в достижении целей, а в самом пути к ним. Каждый момент - это возможность для роста и развития.',
    likes: 24,
    comments: 5,
    date: '2 часа назад'
  },
  {
    id: 2,
    author: {
      name: 'Александр П.',
      avatar: 'https://source.unsplash.com/random/100x100/?man'
    },
    content: 'Сегодня понял, что все наши страхи - это просто иллюзии, созданные нашим умом. Когда мы их отпускаем, открывается истинная свобода.',
    likes: 18,
    comments: 3,
    date: '5 часов назад'
  }
];

interface ConsciousnessProps {
  isPersonal?: boolean;
}

const Consciousness: React.FC<ConsciousnessProps> = ({ isPersonal = false }) => {
  return (
    <Box maxW="800px" mx="auto" py={8} px={4}>
      <VStack spacing={6} align="stretch">
        <Card>
          <CardBody>
            <Textarea
              placeholder="Поделитесь своим осознанием..."
              size="lg"
              resize="vertical"
              mb={4}
            />
            <Button colorScheme="blue" float="right">
              Опубликовать
            </Button>
          </CardBody>
        </Card>

        {mockInsights.map((insight) => (
          <Card key={insight.id}>
            <CardHeader>
              <HStack>
                <Avatar src={insight.author.avatar} name={insight.author.name} />
                <Box>
                  <Heading size="sm">{insight.author.name}</Heading>
                  <Text fontSize="sm" color="gray.500">
                    {insight.date}
                  </Text>
                </Box>
              </HStack>
            </CardHeader>
            <CardBody>
              <Text>{insight.content}</Text>
            </CardBody>
            <CardFooter>
              <HStack spacing={4}>
                <Button
                  variant="ghost"
                  leftIcon={<Icon as={FaHeart} />}
                  size="sm"
                  color="gray.600"
                >
                  {insight.likes}
                </Button>
                <Button
                  variant="ghost"
                  leftIcon={<Icon as={FaComment} />}
                  size="sm"
                  color="gray.600"
                >
                  {insight.comments}
                </Button>
              </HStack>
            </CardFooter>
          </Card>
        ))}
      </VStack>
    </Box>
  );
};

export default Consciousness; 
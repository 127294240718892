import React from 'react';
import { 
  Flex, 
  Tag, 
  TagLabel, 
  TagLeftIcon, 
  Progress, 
  Box,
  Tooltip,
  Badge
} from '@chakra-ui/react';
import { 
  RiAddLine, 
  RiPlayLine, 
  RiPauseLine, 
  RiCheckLine, 
  RiCloseLine 
} from 'react-icons/ri';
import { DreamStatus } from '../../types';

interface DreamStatusProps {
  status: DreamStatus;
  progress?: number;
  showProgress?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const statusConfig = {
  [DreamStatus.NEW]: {
    icon: '🆕',
    color: 'blue',
    text: 'Новая'
  },
  [DreamStatus.IN_PROGRESS]: {
    icon: '🚀',
    color: 'yellow',
    text: 'В процессе'
  },
  [DreamStatus.COMPLETED]: {
    icon: '✅',
    color: 'green',
    text: 'Завершена'
  },
  [DreamStatus.PAUSED]: {
    icon: '⏸️',
    color: 'orange',
    text: 'Приостановлена'
  }
};

const sizeMap = {
  sm: { tag: 'sm', progress: 'xs' },
  md: { tag: 'md', progress: 'sm' },
  lg: { tag: 'lg', progress: 'md' }
};

export const DreamStatusComponent: React.FC<DreamStatusProps> = ({
  status,
  progress = 0,
  showProgress = false,
  size = 'md'
}) => {
  const { icon, color, text } = statusConfig[status];
  const { tag, progress: progressSize } = sizeMap[size];

  return (
    <Flex direction="column" gap={2}>
      <Badge colorScheme={color}>
        {icon} {text}
      </Badge>
      
      {showProgress && (
        <Tooltip label={`Прогресс: ${progress}%`} hasArrow>
          <Box w="full">
            <Progress
              value={progress}
              size={progressSize}
              colorScheme={color}
              borderRadius="full"
              hasStripe
              isAnimated={status === DreamStatus.IN_PROGRESS}
            />
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};

export default DreamStatusComponent; 
import React, { useState } from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useColorModeValue,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
  Divider,
  HStack,
  Link,
  SlideFade,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Progress,
  Tooltip,
} from '@chakra-ui/react';
import { RiEyeLine, RiEyeOffLine, RiGoogleFill, RiAppleFill, RiTelegramFill, RiQuestionLine } from 'react-icons/ri';
import { FaVk } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const MotionVStack = motion(VStack);
const MotionButton = motion(Button);
const MotionInput = motion(Input);
const MotionBox = motion(Box);
const MotionProgress = motion(Progress);

const buttonHoverStyle = {
  scale: 1.02,
  backgroundColor: "rgba(194, 68, 175, 0.1)",
  transition: { duration: 0.2 }
};

const socialButtonHoverStyle = {
  scale: 1.02,
  backgroundColor: "rgba(194, 68, 175, 0.1)",
  color: "brand.400",
  borderColor: "brand.400",
  transition: { duration: 0.2 }
};

const inputFocusStyle = {
  scale: 1.01,
  boxShadow: "0 0 0 3px rgba(194, 68, 175, 0.3)",
  transition: { duration: 0.2 }
};

const progressVariants = {
  initial: { width: 0 },
  animate: (value: number) => ({
    width: `${value}%`,
    transition: { duration: 0.5, ease: "easeOut" }
  })
};

const errorMessageVariants = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.2 } },
  exit: { opacity: 0, y: 10, transition: { duration: 0.2 } }
};

const formControlVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.3 }
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: { duration: 0.2 }
  }
};

interface FormData {
  email: string;
  password: string;
  confirmPassword?: string;
  name?: string;
}

interface FormErrors {
  email?: string;
  password?: string;
  confirmPassword?: string;
  name?: string;
}

interface PasswordStrength {
  score: number;
  color: string;
  text: string;
}

const getPasswordStrength = (password: string): PasswordStrength => {
  let score = 0;
  if (password.length >= 8) score++;
  if (/[A-Z]/.test(password)) score++;
  if (/[a-z]/.test(password)) score++;
  if (/[0-9]/.test(password)) score++;
  if (/[!@#$%^&*]/.test(password)) score++;

  const strengthMap: { [key: number]: PasswordStrength } = {
    0: { score: 0, color: 'red.500', text: 'Очень слабый' },
    1: { score: 20, color: 'red.300', text: 'Слабый' },
    2: { score: 40, color: 'yellow.400', text: 'Средний' },
    3: { score: 60, color: 'green.400', text: 'Хороший' },
    4: { score: 80, color: 'green.500', text: 'Сильный' },
    5: { score: 100, color: 'green.600', text: 'Очень сильный' }
  };

  return strengthMap[score];
};

const tabVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  exit: { 
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2
    }
  }
};

const formVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
};

interface AuthProps {
  isModal?: boolean;
  onClose?: () => void;
}

const Auth = ({ isModal, onClose }: AuthProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
    confirmPassword: '',
    name: ''
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const { isOpen, onOpen, onClose: disclosureClose } = useDisclosure();
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>({ score: 0, color: 'red.500', text: 'Очень слабый' });
  const [activeTab, setActiveTab] = useState(0);

  const validatePassword = (password: string): string | undefined => {
    if (password.length < 8) {
      return 'Пароль должен быть не менее 8 символов';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Пароль должен содержать хотя бы одну заглавную букву';
    }
    if (!/[a-z]/.test(password)) {
      return 'Пароль должен содержать хотя бы одну строчную букву';
    }
    if (!/[0-9]/.test(password)) {
      return 'Пароль должен содержать хотя бы одну цифру';
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return 'Пароль должен содержать хотя бы один специальный символ (!@#$%^&*)';
    }
    return undefined;
  };

  const validateForm = (data: FormData, isRegistration = false): FormErrors => {
    const errors: FormErrors = {};
    
    if (!data.email) {
      errors.email = 'Email обязателен';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Неверный формат email';
    }

    if (!data.password) {
      errors.password = 'Пароль обязателен';
    } else {
      const passwordError = validatePassword(data.password);
      if (passwordError) {
        errors.password = passwordError;
      }
    }

    if (isRegistration) {
      if (!data.name) {
        errors.name = 'Имя обязательно';
      }
      
      if (!data.confirmPassword) {
        errors.confirmPassword = 'Подтвердите пароль';
      } else if (data.password !== data.confirmPassword) {
        errors.confirmPassword = 'Пароли не совпадают';
      }
    }

    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    if (errors[name as keyof FormErrors]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }

    if (name === 'password') {
      setPasswordStrength(getPasswordStrength(value));
    }
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    // Здесь будет логика авторизации
    setTimeout(() => {
      setIsLoading(false);
      toast({
        title: 'Добро пожаловать!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }, 1000);
  };

  const handleRegister = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors = validateForm(formData, true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    // Здесь будет логика регистрации
    setTimeout(() => {
      setIsLoading(false);
      toast({
        title: 'Регистрация успешна!',
        description: 'Добро пожаловать в One Dream',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }, 1000);
  };

  const handleResetPassword = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.email) {
      setErrors({ email: 'Введите email для восстановления' });
      return;
    }
    setIsLoading(true);
    // Здесь будет логика восстановления пароля
    setTimeout(() => {
      setIsLoading(false);
      disclosureClose();
      toast({
        title: 'Инструкции отправлены',
        description: 'Проверьте вашу почту для восстановления пароля',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }, 1000);
  };

  return (
    <Container maxW="container.sm" py={isModal ? 4 : 10}>
      <MotionBox
        bg={bgColor}
        p={8}
        borderRadius="lg"
        boxShadow={isModal ? 'none' : 'lg'}
        border={isModal ? 'none' : '1px'}
        borderColor={borderColor}
        overflow="hidden"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Tabs 
          isFitted 
          variant="soft-rounded" 
          colorScheme="purple"
          onChange={(index) => setActiveTab(index)}
        >
          <TabList mb="1em">
            <Tab>Войти</Tab>
            <Tab>Регистрация</Tab>
          </TabList>
          <TabPanels>
            {/* Форма входа */}
            <TabPanel>
              <motion.div
                variants={tabVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <MotionVStack
                  as="form"
                  spacing={4}
                  onSubmit={handleLogin}
                  variants={formVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <Heading size="lg">Добро пожаловать!</Heading>
                  <Text color="gray.600">Войдите в свой аккаунт</Text>
                  
                  <FormControl isRequired isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <MotionInput
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="your@email.com"
                      whileFocus={inputFocusStyle}
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl isRequired isInvalid={!!errors.password}>
                    <FormLabel>Пароль</FormLabel>
                    <InputGroup>
                      <MotionInput
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Введите пароль"
                        whileFocus={inputFocusStyle}
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
                          icon={showPassword ? <RiEyeOffLine /> : <RiEyeLine />}
                          variant="ghost"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>

                  <Link
                    color="brand.400"
                    alignSelf="flex-end"
                    fontSize="sm"
                    onClick={onOpen}
                    _hover={{ textDecoration: 'underline', color: "brand.500" }}
                  >
                    Забыли пароль?
                  </Link>

                  <MotionButton
                    type="submit"
                    colorScheme="brand"
                    size="lg"
                    width="full"
                    isLoading={isLoading}
                    whileHover={buttonHoverStyle}
                    whileTap={{ scale: 0.98 }}
                  >
                    Войти
                  </MotionButton>

                  <Divider />

                  <Text color="gray.600">Или войдите через</Text>
                  
                  <HStack spacing={4} width="full">
                    <MotionButton
                      leftIcon={<RiGoogleFill />}
                      width="full"
                      variant="outline"
                      colorScheme="brand"
                      whileHover={socialButtonHoverStyle}
                      whileTap={{ scale: 0.98 }}
                    >
                      Google
                    </MotionButton>
                    <MotionButton
                      leftIcon={<FaVk />}
                      width="full"
                      variant="outline"
                      colorScheme="brand"
                      whileHover={socialButtonHoverStyle}
                      whileTap={{ scale: 0.98 }}
                    >
                      VKontakte
                    </MotionButton>
                  </HStack>
                  <MotionButton
                    leftIcon={<RiAppleFill />}
                    width="full"
                    variant="outline"
                    colorScheme="brand"
                    whileHover={socialButtonHoverStyle}
                    whileTap={{ scale: 0.98 }}
                  >
                    Apple
                  </MotionButton>
                </MotionVStack>
              </motion.div>
            </TabPanel>

            {/* Форма регистрации */}
            <TabPanel>
              <motion.div
                variants={tabVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <MotionVStack
                  as="form"
                  spacing={4}
                  onSubmit={handleRegister}
                  variants={formVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <Heading size="lg">Создайте аккаунт</Heading>
                  <Text color="gray.600">Присоединяйтесь к сообществу мечтателей</Text>

                  <FormControl isRequired isInvalid={!!errors.name}>
                    <FormLabel>Имя</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Ваше имя"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl isRequired isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="your@email.com"
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl 
                    isRequired 
                    isInvalid={!!errors.password}
                    as={motion.div}
                    variants={formControlVariants}
                  >
                    <FormLabel>
                      Пароль
                      <Tooltip label="Минимум 8 символов, заглавные и строчные буквы, цифры, спецсимволы" placement="top">
                        <IconButton
                          aria-label="Подсказка"
                          icon={<RiQuestionLine />}
                          size="xs"
                          variant="ghost"
                          ml={1}
                          color="brand.400"
                          _hover={{ bg: "rgba(194, 68, 175, 0.1)" }}
                        />
                      </Tooltip>
                    </FormLabel>
                    <InputGroup>
                      <MotionInput
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Создайте пароль"
                        whileFocus={inputFocusStyle}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      />
                      <InputRightElement>
                        <IconButton
                          as={motion.button}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.95 }}
                          aria-label={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
                          icon={showPassword ? <RiEyeOffLine /> : <RiEyeLine />}
                          variant="ghost"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <AnimatePresence mode="wait">
                      {errors.password && (
                        <motion.div
                          variants={errorMessageVariants}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                        >
                          <FormErrorMessage>{errors.password}</FormErrorMessage>
                        </motion.div>
                      )}
                    </AnimatePresence>
                    {formData.password && (
                      <MotionBox mt={2}>
                        <Text 
                          fontSize="sm" 
                          mb={1}
                          as={motion.p}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            ease: "easeInOut",
                            duration: 0.3,
                            type: "keyframes"
                          } as any}
                        >
                          Сложность пароля: {passwordStrength.text}
                        </Text>
                        <MotionProgress
                          value={passwordStrength.score}
                          colorScheme={passwordStrength.color.split('.')[0]}
                          size="sm"
                          borderRadius="full"
                          initial={{ scaleX: 0 }}
                          animate={{ scaleX: 1 }}
                          transition={{ duration: 0.5, ease: "easeOut" }}
                        />
                      </MotionBox>
                    )}
                  </FormControl>

                  <FormControl isRequired isInvalid={!!errors.confirmPassword}>
                    <FormLabel>Подтверждение пароля</FormLabel>
                    <InputGroup>
                      <Input
                        name="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        placeholder="Повторите пароль"
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label={showConfirmPassword ? 'Скрыть пароль' : 'Показать пароль'}
                          icon={showConfirmPassword ? <RiEyeOffLine /> : <RiEyeLine />}
                          variant="ghost"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    colorScheme="brand"
                    size="lg"
                    width="full"
                    isLoading={isLoading}
                  >
                    Зарегистрироваться
                  </Button>

                  <Divider />

                  <Text color="gray.600">Или зарегистрируйтесь через</Text>
                  
                  <HStack spacing={4} width="full">
                    <MotionButton
                      leftIcon={<RiGoogleFill />}
                      width="full"
                      variant="outline"
                      colorScheme="brand"
                      whileHover={socialButtonHoverStyle}
                      whileTap={{ scale: 0.98 }}
                    >
                      Google
                    </MotionButton>
                    <MotionButton
                      leftIcon={<FaVk />}
                      width="full"
                      variant="outline"
                      colorScheme="brand"
                      whileHover={socialButtonHoverStyle}
                      whileTap={{ scale: 0.98 }}
                    >
                      VKontakte
                    </MotionButton>
                  </HStack>
                  <MotionButton
                    leftIcon={<RiAppleFill />}
                    width="full"
                    variant="outline"
                    colorScheme="brand"
                    whileHover={socialButtonHoverStyle}
                    whileTap={{ scale: 0.98 }}
                  >
                    Apple
                  </MotionButton>
                  <MotionButton
                    leftIcon={<RiTelegramFill />}
                    width="full"
                    variant="outline"
                    colorScheme="brand"
                    whileHover={socialButtonHoverStyle}
                    whileTap={{ scale: 0.98 }}
                  >
                    Telegram
                  </MotionButton>
                </MotionVStack>
              </motion.div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </MotionBox>

      {/* Модальное окно восстановления пароля */}
      <Modal isOpen={isOpen} onClose={disclosureClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Восстановление пароля</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack as="form" spacing={4} onSubmit={handleResetPassword}>
              <Text>Введите email, указанный при регистрации</Text>
              <FormControl isRequired isInvalid={!!errors.email}>
                <Input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="your@email.com"
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                colorScheme="brand"
                width="full"
                isLoading={isLoading}
              >
                Отправить инструкции
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Auth; 
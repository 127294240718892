import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Icon,
  Text,
  Avatar,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  RiHomeLine,
  RiSearchLine,
  RiCompassLine,
  RiMessageLine,
  RiHeartLine,
  RiUserLine,
  RiMoreLine,
  RiStarLine,
  RiLightbulbLine,
} from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';

const menuItems = [
  { icon: RiHomeLine, label: 'Главная', path: '/' },
  { icon: RiStarLine, label: 'Банк мечт', path: '/dreams' },
  { icon: RiHeartLine, label: 'Мои мечты', path: '/my-dreams' },
  { icon: RiLightbulbLine, label: 'Осознания', path: '/consciousness' },
  { icon: RiMessageLine, label: 'Сообщения', path: '/messages' },
  { icon: RiCompassLine, label: 'Сообщество', path: '/community' },
  { icon: RiUserLine, label: 'Профиль', path: '/profile' },
];

const Sidebar = () => {
  const hoverColor = useColorModeValue('gray.100', 'gray.700');
  const activeColor = useColorModeValue('black', 'white');

  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      h="100vh"
      w="240px"
      borderRight="1px"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      py={4}
      px={4}
    >
      <VStack spacing={8} align="stretch">
        {/* Логотип */}
        <Box py={2}>
          <Text fontSize="2xl" fontWeight="bold" color="brand.500">
            OneDream
          </Text>
        </Box>

        {/* Меню */}
        <VStack spacing={1} align="stretch">
          {menuItems.map((item) => (
            <Button
              key={item.path}
              as={RouterLink}
              to={item.path}
              variant="ghost"
              justifyContent="flex-start"
              leftIcon={<Icon as={item.icon} boxSize={6} />}
              _hover={{ bg: hoverColor }}
              _active={{ bg: hoverColor }}
              color={activeColor}
              fontWeight="normal"
              h="50px"
            >
              {item.label}
            </Button>
          ))}
        </VStack>

        {/* Профиль */}
        <Box position="absolute" bottom={4} left={4} right={4}>
          <Button
            variant="ghost"
            justifyContent="flex-start"
            leftIcon={<Avatar size="sm" name="User Name" />}
            rightIcon={<Icon as={RiMoreLine} />}
            _hover={{ bg: hoverColor }}
            _active={{ bg: hoverColor }}
            color={activeColor}
            fontWeight="normal"
            h="50px"
          >
            Профиль
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default Sidebar; 
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Text,
  SimpleGrid,
  useColorModeValue,
  List,
  ListItem,
  Collapse,
  HStack,
  Image,
  FormHelperText,
} from '@chakra-ui/react';
import { DREAM_CATEGORIES } from '../../constants/dreamCategories';
import { RiImageAddLine } from 'react-icons/ri';

interface DreamFormProps {
  onSubmit: (dream: {
    title: string;
    description: string;
    category: string;
    subcategory: string;
    image: File | null;
  }) => void;
  isLoading?: boolean;
}

export const DreamForm: React.FC<DreamFormProps> = ({ onSubmit, isLoading }) => {
  const [description, setDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [customSubcategory, setCustomSubcategory] = useState('');
  const [isSubcategoriesOpen, setIsSubcategoriesOpen] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const [isVisualizing, setIsVisualizing] = useState(false);

  const categoryBg = useColorModeValue('white', 'gray.800');
  const categoryHoverBg = useColorModeValue('gray.50', 'gray.700');
  const selectedBg = useColorModeValue('brand.50', 'brand.900');

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVisualize = () => {
    setIsVisualizing(true);
    // Здесь будет логика визуализации мечты
    setTimeout(() => {
      setIsVisualizing(false);
    }, 2000);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const finalSubcategory = selectedSubcategory === 'Другое...' ? customSubcategory : selectedSubcategory;
    onSubmit({
      title: finalSubcategory,
      description,
      category: selectedCategory,
      subcategory: finalSubcategory,
      image,
    });
  };

  const selectedCategoryData = DREAM_CATEGORIES.find(cat => cat.id === selectedCategory);

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <VStack spacing={6} align="stretch">
        <FormControl isRequired>
          <FormLabel>Категория</FormLabel>
          <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
            {DREAM_CATEGORIES.map((category) => (
              <Box
                key={category.id}
                p={4}
                bg={selectedCategory === category.id ? selectedBg : categoryBg}
                borderRadius="lg"
                cursor="pointer"
                onClick={() => {
                  setSelectedCategory(category.id);
                  setSelectedSubcategory('');
                  setCustomSubcategory('');
                  setIsSubcategoriesOpen(true);
                }}
                _hover={{ bg: categoryHoverBg }}
                transition="all 0.2s"
                boxShadow="sm"
              >
                <VStack spacing={2}>
                  <Text fontSize="2xl">{category.icon}</Text>
                  <Text fontSize="sm" textAlign="center">
                    {category.title}
                  </Text>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </FormControl>

        {selectedCategory && (
          <FormControl isRequired>
            <FormLabel>Подкатегория</FormLabel>
            <Collapse in={isSubcategoriesOpen}>
              <List spacing={2}>
                {selectedCategoryData?.subcategories.map((subcategory) => (
                  <ListItem
                    key={subcategory}
                    p={2}
                    bg={selectedSubcategory === subcategory ? selectedBg : categoryBg}
                    borderRadius="md"
                    cursor="pointer"
                    onClick={() => setSelectedSubcategory(subcategory)}
                    _hover={{ bg: categoryHoverBg }}
                    transition="all 0.2s"
                  >
                    <Text>{subcategory}</Text>
                  </ListItem>
                ))}
              </List>
              {selectedSubcategory === 'Другое...' && (
                <Input
                  mt={2}
                  placeholder="Введите свою подкатегорию..."
                  value={customSubcategory}
                  onChange={(e) => setCustomSubcategory(e.target.value)}
                  isRequired
                />
              )}
            </Collapse>
          </FormControl>
        )}

        <FormControl isRequired>
          <FormLabel>Описание</FormLabel>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Опишите вашу мечту подробнее..."
            rows={5}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Изображение</FormLabel>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            display="none"
            id="dream-image"
          />
          <HStack spacing={4} mb={2}>
            <Button
              as="label"
              htmlFor="dream-image"
              leftIcon={<RiImageAddLine />}
              cursor="pointer"
            >
              Загрузить изображение
            </Button>
            <Button
              onClick={handleVisualize}
              colorScheme="purple"
              isLoading={isVisualizing}
              loadingText="Визуализация..."
            >
              Визуализировать мечту
            </Button>
          </HStack>
          <FormHelperText>
            Добавьте изображение, которое вдохновляет вас, или позвольте нам визуализировать вашу мечту
          </FormHelperText>
          {imagePreview && (
            <Box mt={2}>
              <Image
                src={imagePreview}
                alt="Preview"
                maxH="200px"
                objectFit="cover"
                borderRadius="md"
              />
            </Box>
          )}
        </FormControl>

        <Button
          type="submit"
          colorScheme="brand"
          isLoading={isLoading}
          isDisabled={
            !description || 
            !selectedCategory || 
            !selectedSubcategory || 
            (selectedSubcategory === 'Другое...' && !customSubcategory)
          }
        >
          Добавить мечту
        </Button>
      </VStack>
    </Box>
  );
}; 
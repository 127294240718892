import React from 'react';
import { Container, Spinner, Center } from '@chakra-ui/react';
import UserProfile from '../components/Profile/UserProfile';
import { useAuth } from '../contexts/AuthContext';

const ProfilePage: React.FC = () => {
    const { user, isLoading } = useAuth();

    if (isLoading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" color="brand.500" />
            </Center>
        );
    }

    if (!user) {
        return null;
    }

    return (
        <Container maxW="container.lg" py={8}>
            <UserProfile user={user} />
        </Container>
    );
};

export default ProfilePage; 
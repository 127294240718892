import axios from 'axios';
import type { ApiErrorResponse } from '../utils/api-errors';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000/api/v1';

export interface LoginData {
    username: string;
    password: string;
}

export interface RegisterData {
    email: string;
    username: string;
    password: string;
    full_name: string;
}

export interface TokenResponse {
    access_token: string;
    token_type: string;
}

export interface UserData {
    id: number;
    email: string;
    username: string;
    full_name: string;
    avatar_url: string | null;
    cover_url?: string | null;
    bio: string | null;
    location: string | null;
    website: string | null;
    phone_number: string | null;
    status?: string;
    rank: string | null;
    rank_progress?: any;
    stats?: any;
    followers_count: number;
    following_count: number;
    dreams_count: number;
    points: number;
    is_active: boolean;
    is_superuser?: boolean;
    last_seen?: string;
    created_at: string;
    updated_at: string | null;
}

// Константы для валидации
const VALIDATION = {
    PASSWORD: {
        MIN_LENGTH: 6,
        MAX_LENGTH: 128,
        PATTERN: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/,
    },
    USERNAME: {
        MIN_LENGTH: 3,
        MAX_LENGTH: 32,
        PATTERN: /^[a-zA-Z0-9_-]+$/,
    },
    EMAIL: {
        PATTERN: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    },
    FULL_NAME: {
        MIN_LENGTH: 2,
        MAX_LENGTH: 128,
        PATTERN: /^[a-zA-Zа-яА-Я\s-]+$/,
    },
};

// Функции валидации
const validatePassword = (password: string): string | null => {
    if (!password) return 'Пароль обязателен';
    if (password.length < VALIDATION.PASSWORD.MIN_LENGTH) {
        return `Пароль должен содержать не менее ${VALIDATION.PASSWORD.MIN_LENGTH} символов`;
    }
    if (password.length > VALIDATION.PASSWORD.MAX_LENGTH) {
        return `Пароль не должен превышать ${VALIDATION.PASSWORD.MAX_LENGTH} символов`;
    }
    if (!VALIDATION.PASSWORD.PATTERN.test(password)) {
        return 'Пароль должен содержать хотя бы одну заглавную букву, одну строчную букву и одну цифру';
    }
    return null;
};

const validateUsername = (username: string): string | null => {
    if (!username) return 'Имя пользователя обязательно';
    if (username.length < VALIDATION.USERNAME.MIN_LENGTH) {
        return `Имя пользователя должно содержать не менее ${VALIDATION.USERNAME.MIN_LENGTH} символов`;
    }
    if (username.length > VALIDATION.USERNAME.MAX_LENGTH) {
        return `Имя пользователя не должно превышать ${VALIDATION.USERNAME.MAX_LENGTH} символов`;
    }
    if (!VALIDATION.USERNAME.PATTERN.test(username)) {
        return 'Имя пользователя может содержать только латинские буквы, цифры, дефис и нижнее подчеркивание';
    }
    return null;
};

const validateEmail = (email: string): string | null => {
    if (!email) return 'Email обязателен';
    if (!VALIDATION.EMAIL.PATTERN.test(email)) {
        return 'Введите корректный email адрес';
    }
    return null;
};

const validateFullName = (fullName: string): string | null => {
    if (!fullName) return 'Полное имя обязательно';
    if (fullName.length < VALIDATION.FULL_NAME.MIN_LENGTH) {
        return `Полное имя должно содержать не менее ${VALIDATION.FULL_NAME.MIN_LENGTH} символов`;
    }
    if (fullName.length > VALIDATION.FULL_NAME.MAX_LENGTH) {
        return `Полное имя не должно превышать ${VALIDATION.FULL_NAME.MAX_LENGTH} символов`;
    }
    if (!VALIDATION.FULL_NAME.PATTERN.test(fullName)) {
        return 'Полное имя может содержать только буквы, пробелы и дефис';
    }
    return null;
};

const authApi = {
    login: async (data: LoginData): Promise<TokenResponse> => {
        const response = await axios.post(`${API_URL}/auth/login`, data);
        return response.data;
    },

    register: async (data: RegisterData): Promise<TokenResponse> => {
        try {
            const emailError = validateEmail(data.email);
            if (emailError) throw new Error(emailError);

            const usernameError = validateUsername(data.username);
            if (usernameError) throw new Error(usernameError);

            const passwordError = validatePassword(data.password);
            if (passwordError) throw new Error(passwordError);

            const fullNameError = validateFullName(data.full_name);
            if (fullNameError) throw new Error(fullNameError);

            const response = await axios.post(`${API_URL}/auth/register`, data);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw error;
            }
            throw new Error((error as Error).message);
        }
    },

    getCurrentUser: async (token: string): Promise<UserData> => {
        try {
            if (!token) {
                throw new Error('Токен авторизации отсутствует');
            }

            const response = await axios.get<UserData>(`${API_URL}/auth/me`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw error;
            }
            throw new Error((error as Error).message);
        }
    }
};

export default authApi; 
import React, { useState } from 'react';
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  Avatar,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Badge,
  Icon,
  useToast,
  Flex,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  RiUserLine,
  RiHeartLine,
  RiLightbulbLine,
  RiSettingsLine,
  RiLogoutBoxLine,
} from 'react-icons/ri';
import { useAuth } from '../../contexts/AuthContext';
import Dreams from '../../pages/Dreams';
import Consciousness from '../../pages/Consciousness';

interface UserProfileProps {
  user: {
    full_name: string;
    username: string;
    avatar_url?: string;
    rank?: string;
    points?: number;
    dreams_count?: number;
    followers_count?: number;
    following_count?: number;
    bio?: string;
    location?: string;
    website?: string;
  };
}

const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
  const { logout } = useAuth();
  const toast = useToast();
  const [activeTab, setActiveTab] = useState(0);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const handleLogout = async () => {
    try {
      await logout();
      toast({
        title: 'Выход выполнен',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Ошибка при выходе',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {/* Шапка профиля */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="space-between"
        mb={8}
        p={6}
        bg={bgColor}
        borderRadius="lg"
        boxShadow="sm"
        border="1px"
        borderColor={borderColor}
      >
        <HStack spacing={6}>
          <Avatar
            size="2xl"
            name={user.full_name}
            src={user.avatar_url}
          />
          <VStack align="start" spacing={2}>
            <Heading size="lg">{user.full_name}</Heading>
            <Text color="gray.500">@{user.username}</Text>
            <HStack spacing={4}>
              <Badge colorScheme="brand" fontSize="sm">
                {user.rank || 'Новичок'}
              </Badge>
              <Text fontSize="sm" color="gray.500">
                {user.points || 0} очков
              </Text>
            </HStack>
          </VStack>
        </HStack>

        <HStack spacing={4} mt={{ base: 4, md: 0 }}>
          <Button
            leftIcon={<Icon as={RiSettingsLine} />}
            variant="outline"
            colorScheme="brand"
          >
            Настройки
          </Button>
          <Button
            leftIcon={<Icon as={RiLogoutBoxLine} />}
            variant="outline"
            colorScheme="red"
            onClick={handleLogout}
          >
            Выйти
          </Button>
        </HStack>
      </Flex>

      {/* Статистика */}
      <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4} mb={8}>
        <Box
          p={4}
          bg={bgColor}
          borderRadius="lg"
          boxShadow="sm"
          border="1px"
          borderColor={borderColor}
          textAlign="center"
        >
          <Text fontSize="2xl" fontWeight="bold" color="brand.500">
            {user.dreams_count || 0}
          </Text>
          <Text fontSize="sm" color="gray.500">
            Мечт
          </Text>
        </Box>
        <Box
          p={4}
          bg={bgColor}
          borderRadius="lg"
          boxShadow="sm"
          border="1px"
          borderColor={borderColor}
          textAlign="center"
        >
          <Text fontSize="2xl" fontWeight="bold" color="brand.500">
            {user.followers_count || 0}
          </Text>
          <Text fontSize="sm" color="gray.500">
            Подписчиков
          </Text>
        </Box>
        <Box
          p={4}
          bg={bgColor}
          borderRadius="lg"
          boxShadow="sm"
          border="1px"
          borderColor={borderColor}
          textAlign="center"
        >
          <Text fontSize="2xl" fontWeight="bold" color="brand.500">
            {user.following_count || 0}
          </Text>
          <Text fontSize="sm" color="gray.500">
            Подписок
          </Text>
        </Box>
        <Box
          p={4}
          bg={bgColor}
          borderRadius="lg"
          boxShadow="sm"
          border="1px"
          borderColor={borderColor}
          textAlign="center"
        >
          <Text fontSize="2xl" fontWeight="bold" color="brand.500">
            {user.points || 0}
          </Text>
          <Text fontSize="sm" color="gray.500">
            Очков
          </Text>
        </Box>
      </SimpleGrid>

      {/* Вкладки */}
      <Tabs
        variant="enclosed"
        onChange={(index) => setActiveTab(index)}
        defaultIndex={0}
      >
        <TabList>
          <Tab>
            <Icon as={RiUserLine} mr={2} />
            О себе
          </Tab>
          <Tab>
            <Icon as={RiHeartLine} mr={2} />
            Мои мечты
          </Tab>
          <Tab>
            <Icon as={RiLightbulbLine} mr={2} />
            Мои осознания
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box
              p={6}
              bg={bgColor}
              borderRadius="lg"
              boxShadow="sm"
              border="1px"
              borderColor={borderColor}
            >
              <VStack align="start" spacing={4}>
                <Box>
                  <Text fontWeight="bold">О себе:</Text>
                  <Text>{user.bio || 'Пока ничего не рассказано о себе'}</Text>
                </Box>
                <Divider />
                <Box>
                  <Text fontWeight="bold">Местоположение:</Text>
                  <Text>{user.location || 'Не указано'}</Text>
                </Box>
                <Divider />
                <Box>
                  <Text fontWeight="bold">Веб-сайт:</Text>
                  <Text>{user.website || 'Не указан'}</Text>
                </Box>
              </VStack>
            </Box>
          </TabPanel>
          <TabPanel>
            <Dreams isPersonal={true} />
          </TabPanel>
          <TabPanel>
            <Consciousness isPersonal={true} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default UserProfile; 
import React, { useState } from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Button,
  HStack,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Image,
  useDisclosure,
  FormHelperText,
  useToast,
} from '@chakra-ui/react';
import { RiSearchLine, RiAddLine, RiImageAddLine } from 'react-icons/ri';
import DreamCard from '../components/Dreams/DreamCard';
import { DreamForm } from '../components/Dreams/DreamForm';
import { Dream, DreamStatus, UserRank } from '../types';

interface DreamsProps {
  isPersonal?: boolean;
}

const Dreams: React.FC<DreamsProps> = ({ isPersonal = false }) => {
  const [filter, setFilter] = useState<DreamStatus | 'all'>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bookmarkedDreams, setBookmarkedDreams] = useState<string[]>([]);
  const toast = useToast();
  
  // Состояния для формы создания мечты
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const [isVisualizing, setIsVisualizing] = useState(false);

  // В реальном приложении эти данные будут получены из API
  const mockDreams: Dream[] = [
    {
      id: '1',
      title: 'Путешествие в Японию',
      description: 'Хочу посетить Японию, изучить культуру и традиции этой удивительной страны. Особенно интересуют храмы Киото и современный Токио.',
      status: DreamStatus.IN_PROGRESS,
      progress: 45,
      createdAt: new Date('2024-01-15'),
      updatedAt: new Date('2024-03-20'),
      userId: '1',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1492571350019-22de08371fd3?w=800&h=600&fit=crop',
      authorName: 'Анна Петрова',
      authorAvatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=100&h=100&fit=crop',
      authorRank: UserRank.REALITY_CREATOR,
      isLiked: true,
      likesCount: 42,
      commentsCount: 12,
    },
    {
      id: '2',
      title: 'Запустить стартап',
      description: 'Создать инновационный продукт, который поможет людям решать их повседневные проблемы. Фокус на экологичности и устойчивом развитии.',
      status: DreamStatus.NEW,
      progress: 0,
      createdAt: new Date('2024-03-01'),
      updatedAt: new Date('2024-03-01'),
      userId: '2',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?w=800&h=600&fit=crop',
      authorName: 'Михаил Иванов',
      authorAvatar: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?w=100&h=100&fit=crop',
      authorRank: UserRank.OPPORTUNITY_SEEKER,
      isLiked: false,
      likesCount: 15,
      commentsCount: 5,
    },
    {
      id: '3',
      title: 'Марафон',
      description: 'Пробежать полный марафон. Тренируюсь каждый день, постепенно увеличивая дистанцию. Цель - марафон в следующем году.',
      status: DreamStatus.COMPLETED,
      progress: 100,
      createdAt: new Date('2023-06-10'),
      updatedAt: new Date('2024-02-28'),
      userId: '3',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1552674605-db6ffd4facb5?w=800&h=600&fit=crop',
      authorName: 'Елена Сидорова',
      authorAvatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
      authorRank: UserRank.GOAL_ACHIEVER,
      isLiked: true,
      likesCount: 89,
      commentsCount: 24,
    },
    {
      id: '4',
      title: 'Научиться играть на пианино',
      description: 'Освоить классическое пианино, научиться играть произведения Шопена и Бетховена. Заниматься каждый день по 2 часа.',
      status: DreamStatus.IN_PROGRESS,
      progress: 35,
      createdAt: new Date('2024-02-01'),
      updatedAt: new Date('2024-03-15'),
      userId: '4',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1520523839897-bd0b52f945a0?w=800&h=600&fit=crop',
      authorName: 'Дмитрий Козлов',
      authorAvatar: 'https://images.unsplash.com/photo-1599566150163-29194dcaad36?w=100&h=100&fit=crop',
      authorRank: UserRank.BEGINNER_DREAMER,
      isLiked: false,
      likesCount: 27,
      commentsCount: 8,
    },
    {
      id: '5',
      title: 'Открыть кофейню',
      description: 'Создать уютное место с авторским кофе и свежей выпечкой. Место, где каждый гость будет чувствовать себя как дома.',
      status: DreamStatus.PAUSED,
      progress: 20,
      createdAt: new Date('2024-01-20'),
      updatedAt: new Date('2024-03-10'),
      userId: '5',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1501339847302-ac426a4a7cbb?w=800&h=600&fit=crop',
      authorName: 'Мария Волкова',
      authorAvatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
      authorRank: UserRank.INSPIRATOR,
      isLiked: true,
      likesCount: 56,
      commentsCount: 19,
    },
    {
      id: '6',
      title: 'Написать книгу',
      description: 'Создать увлекательный роман о путешествиях во времени. Исследовать темы судьбы, выбора и последствий наших решений.',
      status: DreamStatus.NEW,
      progress: 0,
      createdAt: new Date('2024-03-05'),
      updatedAt: new Date('2024-03-05'),
      userId: '6',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1506880018603-83d5b814b5a6?w=800&h=600&fit=crop',
      authorName: 'Александр Новиков',
      authorAvatar: 'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?w=100&h=100&fit=crop',
      authorRank: UserRank.DREAM_ARCHITECT,
      isLiked: false,
      likesCount: 31,
      commentsCount: 11,
    }
  ];

  const handleStatusChange = (dreamId: string, newStatus: DreamStatus) => {
    // Здесь будет логика обновления статуса мечты через API
    console.log(`Обновление статуса мечты ${dreamId} на ${newStatus}`);
  };

  const handleLike = (dreamId: string) => {
    // Здесь будет логика лайка мечты через API
    console.log(`Лайк мечты ${dreamId}`);
  };

  const handleComment = (dreamId: string) => {
    // Здесь будет логика открытия комментариев
    console.log(`Открытие комментариев мечты ${dreamId}`);
  };

  const handleBookmark = (dreamId: string) => {
    setBookmarkedDreams(prev => {
      const isBookmarked = prev.includes(dreamId);
      const newBookmarks = isBookmarked
        ? prev.filter(id => id !== dreamId)
        : [...prev, dreamId];
      
      toast({
        title: isBookmarked ? 'Удалено из закладок' : 'Добавлено в закладки',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      
      return newBookmarks;
    });
  };

  const handleShare = (dream: Dream) => {
    toast({
      title: 'Поделиться мечтой',
      description: `${dream.title}`,
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  const filteredDreams = mockDreams
    .filter(dream => filter === 'all' || dream.status === filter)
    .filter(dream => 
      searchQuery === '' || 
      dream.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      dream.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

  // Обработчик загрузки изображения
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Обработчик визуализации
  const handleVisualize = () => {
    setIsVisualizing(true);
    // Здесь будет логика визуализации мечты
    setTimeout(() => {
      setIsVisualizing(false);
    }, 2000);
  };

  const handleCreateDream = (dream: {
    title: string;
    description: string;
    category: string;
    subcategory: string;
  }) => {
    // Здесь будет логика создания мечты
    console.log('Creating dream:', dream);
    onClose();
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Box mb={8}>
        <Heading mb={6}>Банк мечт</Heading>
        <HStack spacing={4} mb={6}>
          <InputGroup maxW="400px">
            <InputLeftElement pointerEvents="none">
              <RiSearchLine color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Поиск мечты..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
          <Select
            maxW="200px"
            value={filter}
            onChange={(e) => setFilter(e.target.value as DreamStatus | 'all')}
          >
            <option value="all">Все статусы</option>
            {Object.values(DreamStatus).map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
          <Button
            leftIcon={<RiAddLine />}
            colorScheme="brand"
            onClick={onOpen}
          >
            Новая мечта
          </Button>
        </HStack>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {filteredDreams.map((dream) => (
          <DreamCard
            key={dream.id}
            dream={{
              ...dream,
              isBookmarked: bookmarkedDreams.includes(dream.id)
            }}
            onStatusChange={(status) => handleStatusChange(dream.id, status)}
            onLike={() => handleLike(dream.id)}
            onComment={() => handleComment(dream.id)}
            onBookmark={() => handleBookmark(dream.id)}
            onShare={() => handleShare(dream)}
          />
        ))}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>Создать новую мечту</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <DreamForm onSubmit={handleCreateDream} isLoading={false} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Dreams; 
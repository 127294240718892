export interface DreamCategory {
  id: string;
  icon: string;
  title: string;
  subcategories: string[];
}

export const DREAM_CATEGORIES: DreamCategory[] = [
  {
    id: 'self_development',
    icon: '🎯',
    title: 'Саморазвитие и достижения',
    subcategories: [
      'Личностный рост',
      'Духовные практики',
      'Новые навыки и образование',
      'Прочитать 100 книг',
      'Освоить иностранный язык',
      'Другое...'
    ]
  },
  {
    id: 'career',
    icon: '💼',
    title: 'Карьера и бизнес',
    subcategories: [
      'Открыть собственное дело',
      'Достичь определенной должности',
      'Запустить стартап',
      'Стать экспертом в своей области',
      'Получить международное признание',
      'Другое...'
    ]
  },
  {
    id: 'travel',
    icon: '🌍',
    title: 'Путешествия и приключения',
    subcategories: [
      'Посетить 10 стран',
      'Поехать в кругосветное путешествие',
      'Побывать на Северном полюсе',
      'Пожить за границей',
      'Исследовать дикие уголки природы',
      'Другое...'
    ]
  },
  {
    id: 'relationships',
    icon: '❤️',
    title: 'Отношения и семья',
    subcategories: [
      'Найти любовь всей жизни',
      'Создать крепкую семью',
      'Родить и воспитать ребенка',
      'Встретить единомышленников',
      'Восстановить отношения с близкими',
      'Другое...'
    ]
  },
  {
    id: 'comfort',
    icon: '🏡',
    title: 'Комфорт и материальные блага',
    subcategories: [
      'Купить дом мечты',
      'Обустроить идеальное рабочее место',
      'Обновить гардероб',
      'Приобрести автомобиль мечты',
      'Создать пассивный доход',
      'Другое...'
    ]
  },
  {
    id: 'creativity',
    icon: '🎨',
    title: 'Творчество и самовыражение',
    subcategories: [
      'Написать книгу',
      'Записать песню или альбом',
      'Сыграть главную роль в фильме',
      'Нарисовать картину, которую выставят в галерее',
      'Открыть свою выставку',
      'Другое...'
    ]
  },
  {
    id: 'sports',
    icon: '⚡',
    title: 'Экстрим и спорт',
    subcategories: [
      'Прыгнуть с парашютом',
      'Освоить серфинг или сноуборд',
      'Пробежать марафон',
      'Прокатиться на суперкаре',
      'Подняться на Эверест',
      'Другое...'
    ]
  },
  {
    id: 'charity',
    icon: '🌿',
    title: 'Благотворительность и осознанность',
    subcategories: [
      'Посадить лес',
      'Помочь детям из детских домов',
      'Поддерживать приюты для животных',
      'Запустить социальный проект',
      'Изменить мир к лучшему',
      'Другое...'
    ]
  },
  {
    id: 'innovation',
    icon: '🛠️',
    title: 'Инновации и технологии',
    subcategories: [
      'Разработать собственное приложение',
      'Изобрести что-то новое',
      'Стать известным IT-специалистом',
      'Запустить NFT-проект',
      'Создать AI, который поможет людям',
      'Другое...'
    ]
  }
]; 
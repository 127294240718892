export enum UserStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away',
  DND = 'dnd' // Do not disturb
}

export enum DreamStatus {
  NEW = 'Новая',
  IN_PROGRESS = 'В процессе',
  COMPLETED = 'Завершена',
  PAUSED = 'Приостановлена'
}

export enum UserRank {
  BEGINNER_DREAMER = 'Начинающий мечтатель',
  OPPORTUNITY_SEEKER = 'Искатель возможностей',
  GOAL_ACHIEVER = 'Достигатор целей',
  DREAM_ARCHITECT = 'Архитектор мечты',
  REALITY_CREATOR = 'Создатель реальности',
  INSPIRATOR = 'Вдохновитель'
}

export interface UserRankRequirements {
  rank: UserRank;
  description: string;
  requirements: string[];
}

export const USER_RANK_REQUIREMENTS: UserRankRequirements[] = [
  {
    rank: UserRank.BEGINNER_DREAMER,
    description: 'Только начинает свой путь осознанности.',
    requirements: ['Добавил хотя бы одну мечту']
  },
  {
    rank: UserRank.OPPORTUNITY_SEEKER,
    description: 'Проявил активность, оставил первые осознания.',
    requirements: [
      'Добавил 3+ мечты и 3+ осознания',
      'Оставил первые лайки и комментарии'
    ]
  },
  {
    rank: UserRank.GOAL_ACHIEVER,
    description: 'Достиг первой мечты и делится этим с сообществом.',
    requirements: [
      'Исполнил хотя бы 1 мечту',
      'Написал осознания, которые получили лайки'
    ]
  },
  {
    rank: UserRank.INSPIRATOR,
    description: 'Мотивирует других, получает лайки и комментарии на свои посты.',
    requirements: [
      'Его осознания/мечты получили 50+ лайков',
      'Комментирует и поддерживает других'
    ]
  },
  {
    rank: UserRank.REALITY_CREATOR,
    description: 'Осознанно творит свою жизнь, активно участвует в обсуждениях.',
    requirements: [
      'Исполнил 5+ мечт',
      'Его осознания вызвали большой резонанс в сообществе'
    ]
  },
  {
    rank: UserRank.DREAM_ARCHITECT,
    description: 'Помогает другим достигать целей, поддерживает сообщество.',
    requirements: [
      'Помог как минимум 5 пользователям приблизиться к их мечтам',
      'Его вклад признан другими'
    ]
  },
  {
    rank: UserRank.REALITY_CREATOR,
    description: 'Высший уровень осознанности, признанный сообществом и администрацией.',
    requirements: [
      'Активно помогает в развитии платформы',
      'Признан как лидер осознанного сообщества'
    ]
  }
];

export interface User {
  id: string;
  name: string;
  avatar: string;
  status: UserStatus;
  rank: UserRank;
  rankProgress: {
    currentRank: UserRank;
    nextRank: UserRank | null;
    progress: number;
    requirements: {
      total: number;
      completed: number;
    };
  };
  stats: {
    dreams: {
      total: number;
      completed: number;
      inProgress: number;
    };
    insights: {
      total: number;
      likes: number;
    };
    community: {
      helpedUsers: number;
      likes: number;
      comments: number;
    };
  };
  lastSeen: Date;
  bio: string;
  location: string;
  followers: number;
  following: number;
}

export interface Author {
  id: string;
  name: string;
  avatar: string;
  rank: UserRank;
}

export interface DreamInteractions {
  likesCount: number;
  commentsCount: number;
  sharesCount: number;
  isLikedByCurrentUser: boolean;
  isBookmarkedByCurrentUser: boolean;
}

export interface Dream {
  id: string;
  title: string;
  description: string;
  status: DreamStatus;
  progress: number;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  isPublic: boolean;
  image?: string;
  authorName?: string;
  authorAvatar?: string;
  authorRank?: UserRank;
  isLiked?: boolean;
  likesCount?: number;
  commentsCount?: number;
}

export interface DreamWithDetails {
  dream: Dream;
  author: Author;
  interactions: DreamInteractions;
}

export interface LoginData {
  email: string;
  password: string;
} 
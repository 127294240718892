import React, { useState } from 'react';
import {
  Box,
  Image,
  Text,
  VStack,
  HStack,
  Avatar,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
  MenuDivider,
  Tooltip,
  useToast,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  RiMoreLine,
  RiHeartLine,
  RiHeartFill,
  RiMessage3Line,
  RiDeleteBinLine,
  RiBookmarkLine,
  RiBookmarkFill,
  RiShareForwardLine,
  RiTelegramLine,
  RiWhatsappLine,
  RiGlobalLine,
  RiLink,
  RiFileCopyLine,
  RiCheckLine,
} from 'react-icons/ri';
import { Dream, DreamStatus } from '../../types';
import { DreamStatusComponent } from '../DreamStatus/DreamStatus';

interface ShareOption {
  icon: React.ElementType;
  label: string;
  color: string;
  action: () => void;
}

interface DreamCardProps {
  dream: Dream & {
    isBookmarked?: boolean;
    authorName?: string;
    authorAvatar?: string;
    authorRank?: string;
    isLiked?: boolean;
    likesCount?: number;
    commentsCount?: number;
  };
  onStatusChange: (status: DreamStatus) => void;
  onLike: () => void;
  onComment: () => void;
  onBookmark: () => void;
  onShare: () => void;
  onDelete?: () => void;
}

const DreamCard: React.FC<DreamCardProps> = ({
  dream,
  onLike,
  onComment,
  onStatusChange,
  onDelete,
  onBookmark,
  onShare
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCopied, setIsCopied] = useState(false);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const activeColor = useColorModeValue('brand.500', 'brand.400');
  const toast = useToast();

  const handleShare = () => {
    if (onShare) {
      onShare();
    }
    onOpen();
  };

  const dreamUrl = `https://onedream.com/dreams/${dream.id}`; // Пример URL

  const copyToClipboard = () => {
    navigator.clipboard.writeText(dreamUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
      toast({
        title: 'Ссылка скопирована',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const shareOptions: ShareOption[] = [
    {
      icon: RiTelegramLine,
      label: 'Telegram',
      color: '#0088cc',
      action: () => window.open(`https://t.me/share/url?url=${dreamUrl}&text=${encodeURIComponent(dream.title)}`)
    },
    {
      icon: RiWhatsappLine,
      label: 'WhatsApp',
      color: '#25D366',
      action: () => window.open(`https://wa.me/?text=${encodeURIComponent(`${dream.title} ${dreamUrl}`)}`)
    },
    {
      icon: RiGlobalLine,
      label: 'VK',
      color: '#4C75A3',
      action: () => window.open(`https://vk.com/share.php?url=${dreamUrl}&title=${encodeURIComponent(dream.title)}`)
    }
  ];

  return (
    <>
      <Box
        bg={bgColor}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        overflow="hidden"
        transition="all 0.2s"
        _hover={{ transform: 'translateY(-4px)', shadow: 'md' }}
      >
        <Box p={4}>
          <HStack justify="space-between" mb={4}>
            <HStack spacing={3} align="start">
              <Avatar size="sm" src={dream.authorAvatar} name={dream.authorName} />
              <VStack spacing={0} align="start">
                <Text fontWeight="medium">{dream.authorName}</Text>
                <Text fontSize="xs" color="gray.500">{dream.authorRank}</Text>
              </VStack>
            </HStack>
            <HStack spacing={2}>
              <Tooltip label={dream.isBookmarked ? 'Удалить из закладок' : 'Добавить в закладки'}>
                <IconButton
                  aria-label="Добавить в закладки"
                  icon={<Icon as={dream.isBookmarked ? RiBookmarkFill : RiBookmarkLine} />}
                  variant="ghost"
                  size="sm"
                  color={dream.isBookmarked ? activeColor : iconColor}
                  onClick={onBookmark}
                />
              </Tooltip>
              <Tooltip label="Поделиться">
                <IconButton
                  aria-label="Поделиться"
                  icon={<Icon as={RiShareForwardLine} />}
                  variant="ghost"
                  size="sm"
                  onClick={handleShare}
                />
              </Tooltip>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<RiMoreLine />}
                  variant="ghost"
                  size="sm"
                  aria-label="Опции"
                />
                <MenuList>
                  {Object.values(DreamStatus).map((status) => (
                    <MenuItem
                      key={status}
                      onClick={() => onStatusChange?.(status)}
                      isDisabled={status === dream.status}
                    >
                      Изменить статус на: {status}
                    </MenuItem>
                  ))}
                  <MenuDivider />
                  <MenuItem
                    icon={<RiDeleteBinLine />}
                    onClick={onDelete}
                    color="red.500"
                  >
                    Удалить мечту
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </HStack>

          {dream.image && (
            <Box borderRadius="md" overflow="hidden" mb={4}>
              <Image
                src={dream.image}
                alt={dream.title}
                h="200px"
                w="100%"
                objectFit="cover"
              />
            </Box>
          )}

          <VStack align="stretch" spacing={4}>
            <Box>
              <Text fontSize="xl" fontWeight="bold" mb={2}>
                {dream.title}
              </Text>
              <Text color="gray.600" noOfLines={3}>
                {dream.description}
              </Text>
            </Box>

            <DreamStatusComponent
              status={dream.status}
              progress={dream.progress}
              showProgress
              size="sm"
            />

            <HStack justify="space-between" pt={2}>
              <HStack spacing={4}>
                <IconButton
                  aria-label="Like"
                  icon={dream.isLiked ? <RiHeartFill /> : <RiHeartLine />}
                  variant="ghost"
                  size="sm"
                  color={dream.isLiked ? 'red.500' : undefined}
                  onClick={onLike}
                />
                <Text fontSize="sm" color="gray.600">
                  {dream.likesCount}
                </Text>
                <IconButton
                  aria-label="Comment"
                  icon={<RiMessage3Line />}
                  variant="ghost"
                  size="sm"
                  onClick={onComment}
                />
                <Text fontSize="sm" color="gray.600">
                  {dream.commentsCount}
                </Text>
              </HStack>
              <Text fontSize="sm" color="gray.500">
                {new Date(dream.createdAt).toLocaleDateString()}
              </Text>
            </HStack>
          </VStack>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Поделиться мечтой</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={6}>
              <InputGroup>
                <Input
                  value={dreamUrl}
                  isReadOnly
                  pr="4.5rem"
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    h="1.75rem"
                    size="sm"
                    aria-label="Копировать ссылку"
                    icon={<Icon as={isCopied ? RiCheckLine : RiFileCopyLine} />}
                    onClick={copyToClipboard}
                  />
                </InputRightElement>
              </InputGroup>

              <SimpleGrid columns={3} spacing={4} width="100%">
                {shareOptions.map((option) => (
                  <Button
                    key={option.label}
                    leftIcon={<Icon as={option.icon} />}
                    onClick={option.action}
                    colorScheme="gray"
                    variant="outline"
                    size="sm"
                    width="100%"
                  >
                    {option.label}
                  </Button>
                ))}
              </SimpleGrid>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DreamCard; 
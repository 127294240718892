import React, { useState } from 'react';
import {
  Box,
  Container,
  HStack,
  VStack,
  Input,
  Avatar,
  Text,
  Icon,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Divider,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { RiSearchLine, RiSendPlaneFill, RiImageLine, RiHeart3Line, RiMore2Fill } from 'react-icons/ri';

interface Message {
  id: number;
  text: string;
  timestamp: string;
  isOwn: boolean;
}

interface Chat {
  id: number;
  user: {
    id: number;
    name: string;
    avatar: string;
    isOnline: boolean;
    lastSeen?: string;
  };
  lastMessage: string;
  timestamp: string;
  unread: number;
}

// Демо-данные для чатов
const chats: Chat[] = [
  {
    id: 1,
    user: {
      id: 1,
      name: 'Анна П.',
      avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=100&h=100&fit=crop',
      isOnline: true,
    },
    lastMessage: 'Спасибо за помощь с моей мечтой! 🙏',
    timestamp: '2024-04-20T10:30:00',
    unread: 2,
  },
  {
    id: 2,
    user: {
      id: 2,
      name: 'Михаил И.',
      avatar: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?w=100&h=100&fit=crop',
      isOnline: false,
      lastSeen: '2024-04-20T09:15:00',
    },
    lastMessage: 'Давай обсудим твою идею подробнее',
    timestamp: '2024-04-19T18:45:00',
    unread: 0,
  },
  {
    id: 3,
    user: {
      id: 3,
      name: 'Елена С.',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
      isOnline: true,
    },
    lastMessage: 'Отличный прогресс! Продолжай в том же духе 💪',
    timestamp: '2024-04-20T08:20:00',
    unread: 1,
  },
];

// Демо-данные для сообщений
const messages: Message[] = [
  {
    id: 1,
    text: 'Привет! Как продвигается твоя мечта?',
    timestamp: '2024-04-20T10:15:00',
    isOwn: false,
  },
  {
    id: 2,
    text: 'Привет! Спасибо, что спрашиваешь. Я уже начал делать первые шаги!',
    timestamp: '2024-04-20T10:20:00',
    isOwn: true,
  },
  {
    id: 3,
    text: 'Это здорово! Расскажи подробнее, что уже удалось сделать?',
    timestamp: '2024-04-20T10:25:00',
    isOwn: false,
  },
];

const Messages = () => {
  const [selectedChat, setSelectedChat] = useState<Chat | null>(chats[0]);
  const [newMessage, setNewMessage] = useState('');
  
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const selectedBg = useColorModeValue('gray.100', 'gray.600');

  // Форматирование времени
  const formatTime = (timestamp: string) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    
    if (days === 0) {
      return date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
    }
    if (days === 1) return 'вчера';
    if (days < 7) return `${days} дн. назад`;
    return date.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit' });
  };

  // Форматирование статуса "был в сети"
  const formatLastSeen = (timestamp?: string) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return `был(а) в сети ${formatTime(timestamp)}`;
  };

  return (
    <Container maxW="container.xl" h="calc(100vh - 80px)" py={4}>
      <HStack h="full" spacing={0} bg={bgColor} rounded="xl" overflow="hidden" border="1px solid" borderColor={borderColor}>
        {/* Список чатов */}
        <Box w="350px" h="full" borderRight="1px solid" borderColor={borderColor}>
          <VStack h="full" spacing={0}>
            {/* Поиск */}
            <Box p={4} w="full">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={RiSearchLine} color="gray.400" />
                </InputLeftElement>
                <Input
                  placeholder="Поиск сообщений"
                  variant="filled"
                  bg={useColorModeValue('gray.100', 'gray.700')}
                />
              </InputGroup>
            </Box>
            
            {/* Список чатов */}
            <VStack spacing={0} w="full" overflowY="auto" flex={1}>
              {chats.map((chat) => (
                <Box
                  key={chat.id}
                  w="full"
                  p={4}
                  cursor="pointer"
                  bg={selectedChat?.id === chat.id ? selectedBg : 'transparent'}
                  _hover={{ bg: selectedChat?.id === chat.id ? selectedBg : hoverBg }}
                  onClick={() => setSelectedChat(chat)}
                >
                  <HStack spacing={3}>
                    <Box position="relative">
                      <Avatar
                        size="md"
                        name={chat.user.name}
                        src={chat.user.avatar}
                      />
                      {chat.user.isOnline && (
                        <Box
                          position="absolute"
                          bottom="2px"
                          right="2px"
                          w="3"
                          h="3"
                          bg="green.500"
                          rounded="full"
                          border="2px solid"
                          borderColor={bgColor}
                        />
                      )}
                    </Box>
                    <VStack spacing={0} align="start" flex={1}>
                      <Text fontWeight="bold" fontSize="sm">
                        {chat.user.name}
                      </Text>
                      <Text fontSize="sm" color="gray.500" noOfLines={1}>
                        {chat.lastMessage}
                      </Text>
                    </VStack>
                    <VStack spacing={1} align="end">
                      <Text fontSize="xs" color="gray.500">
                        {formatTime(chat.timestamp)}
                      </Text>
                      {chat.unread > 0 && (
                        <Box
                          bg="brand.500"
                          color="white"
                          rounded="full"
                          px={2}
                          py={0.5}
                          fontSize="xs"
                          fontWeight="bold"
                        >
                          {chat.unread}
                        </Box>
                      )}
                    </VStack>
                  </HStack>
                </Box>
              ))}
            </VStack>
          </VStack>
        </Box>

        {/* Область чата */}
        {selectedChat ? (
          <VStack flex={1} h="full" spacing={0}>
            {/* Заголовок чата */}
            <HStack w="full" p={4} borderBottom="1px solid" borderColor={borderColor}>
              <Avatar
                size="sm"
                name={selectedChat.user.name}
                src={selectedChat.user.avatar}
              />
              <VStack spacing={0} align="start" flex={1}>
                <Text fontWeight="bold">{selectedChat.user.name}</Text>
                <Text fontSize="xs" color="gray.500">
                  {selectedChat.user.isOnline
                    ? 'в сети'
                    : formatLastSeen(selectedChat.user.lastSeen)}
                </Text>
              </VStack>
              <IconButton
                aria-label="Дополнительные действия"
                icon={<Icon as={RiMore2Fill} />}
                variant="ghost"
              />
            </HStack>

            {/* Сообщения */}
            <VStack
              flex={1}
              w="full"
              spacing={4}
              p={4}
              overflowY="auto"
              align="stretch"
            >
              {messages.map((message) => (
                <Flex
                  key={message.id}
                  justify={message.isOwn ? 'flex-end' : 'flex-start'}
                >
                  <Box
                    maxW="70%"
                    bg={message.isOwn ? 'brand.500' : hoverBg}
                    color={message.isOwn ? 'white' : 'inherit'}
                    px={4}
                    py={2}
                    rounded="2xl"
                    roundedBottomRight={message.isOwn ? '4px' : undefined}
                    roundedBottomLeft={!message.isOwn ? '4px' : undefined}
                  >
                    <Text fontSize="sm">{message.text}</Text>
                    <Text fontSize="xs" color={message.isOwn ? 'whiteAlpha.800' : 'gray.500'} textAlign="right">
                      {formatTime(message.timestamp)}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </VStack>

            {/* Ввод сообщения */}
            <HStack w="full" p={4} borderTop="1px solid" borderColor={borderColor} spacing={3}>
              <IconButton
                aria-label="Прикрепить изображение"
                icon={<Icon as={RiImageLine} />}
                variant="ghost"
                rounded="full"
              />
              <Input
                placeholder="Напишите сообщение..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                variant="filled"
              />
              <IconButton
                aria-label="Отправить сообщение"
                icon={<Icon as={RiSendPlaneFill} />}
                colorScheme="brand"
                rounded="full"
                isDisabled={!newMessage.trim()}
              />
            </HStack>
          </VStack>
        ) : (
          <Flex flex={1} justify="center" align="center">
            <Text color="gray.500">Выберите чат для начала общения</Text>
          </Flex>
        )}
      </HStack>
    </Container>
  );
};

export default Messages; 
import React, { createContext, useContext, useState } from 'react';
import authApi, { LoginData } from '../api/auth';

interface User {
    id: string;
    full_name: string;
    username: string;
    avatar_url?: string;
    rank?: string;
    points?: number;
    dreams_count?: number;
    followers_count?: number;
    following_count?: number;
    bio?: string;
    location?: string;
    website?: string;
}

interface AuthContextType {
    isAuthenticated: boolean;
    user: User | null;
    isLoading: boolean;
    login: (data: LoginData) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [user, setUser] = useState<User | null>({
        id: '1',
        full_name: 'Иван Иванов',
        username: 'ivan',
        avatar_url: 'https://i.pravatar.cc/150?img=1',
        rank: 'Новичок',
        points: 100,
        dreams_count: 5,
        followers_count: 10,
        following_count: 15,
        bio: 'Люблю мечтать и достигать целей',
        location: 'Москва',
        website: 'https://example.com'
    });
    const [isLoading, setIsLoading] = useState(false);

    const login = async (data: LoginData) => {
        const response = await authApi.login(data);
        if (response.access_token) {
            localStorage.setItem('token', response.access_token);
            setIsAuthenticated(true);
        }
    };

    const logout = async () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, isLoading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}; 
import React, { useState } from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import DreamCard from '../components/Dreams/DreamCard';
import { DreamForm } from '../components/Dreams/DreamForm';
import { Dream, DreamStatus, UserRank } from '../types';

const MyDreams = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bookmarkedDreams, setBookmarkedDreams] = useState<string[]>([]);
  const toast = useToast();

  // В реальном приложении эти данные будут получены из API
  const mockMyDreams: Dream[] = [
    {
      id: '1',
      title: 'Написать книгу',
      description: 'Хочу написать научно-фантастический роман о будущем искусственного интеллекта и его влиянии на общество.',
      status: DreamStatus.IN_PROGRESS,
      progress: 35,
      createdAt: new Date('2024-02-01'),
      updatedAt: new Date('2024-03-15'),
      userId: '1',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?w=800&h=600&fit=crop',
      authorName: 'Александр Иванов',
      authorAvatar: 'https://bit.ly/dan-abramov',
      authorRank: UserRank.GOAL_ACHIEVER,
      isLiked: false,
      likesCount: 18,
      commentsCount: 7,
      category: 'Творчество'
    },
    {
      id: '2',
      title: 'Выучить японский',
      description: 'Достичь уровня N3 по японскому языку. Изучаю кандзи и разговорную практику каждый день.',
      status: DreamStatus.NEW,
      progress: 0,
      createdAt: new Date('2024-03-10'),
      updatedAt: new Date('2024-03-10'),
      userId: '1',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1480796927426-f609979314bd?w=800&h=600&fit=crop',
      authorName: 'Александр Иванов',
      authorAvatar: 'https://bit.ly/dan-abramov',
      authorRank: UserRank.GOAL_ACHIEVER,
      isLiked: false,
      likesCount: 5,
      commentsCount: 2,
      category: 'Образование',
      deadline: new Date('2024-12-31')
    },
    {
      id: '3',
      title: 'Создать музыкальную группу',
      description: 'Собрать единомышленников и создать рок-группу. Написать и записать альбом авторских песен.',
      status: DreamStatus.PAUSED,
      progress: 15,
      createdAt: new Date('2024-01-15'),
      updatedAt: new Date('2024-02-20'),
      userId: '1',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee?w=800&h=600&fit=crop',
      authorName: 'Александр Иванов',
      authorAvatar: 'https://bit.ly/dan-abramov',
      authorRank: UserRank.GOAL_ACHIEVER,
      isLiked: true,
      likesCount: 24,
      commentsCount: 9,
      category: 'Творчество'
    },
    {
      id: '4',
      title: 'Путешествие по Европе',
      description: 'Посетить 10 европейских стран за 3 месяца. Изучить местную культуру, архитектуру и кухню.',
      status: DreamStatus.NEW,
      progress: 0,
      createdAt: new Date('2024-03-18'),
      updatedAt: new Date('2024-03-18'),
      userId: '1',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1485081669829-bacb8c7bb1f3?w=800&h=600&fit=crop',
      authorName: 'Александр Иванов',
      authorAvatar: 'https://bit.ly/dan-abramov',
      authorRank: UserRank.GOAL_ACHIEVER,
      isLiked: false,
      likesCount: 12,
      commentsCount: 4,
      category: 'Путешествия'
    },
    {
      id: '5',
      title: 'Марафон по чтению',
      description: 'Прочитать 50 книг за год. Фокус на классической литературе и современной научной фантастике.',
      status: DreamStatus.IN_PROGRESS,
      progress: 42,
      createdAt: new Date('2024-01-01'),
      updatedAt: new Date('2024-03-19'),
      userId: '1',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1481627834876-b7833e8f5570?w=800&h=600&fit=crop',
      authorName: 'Александр Иванов',
      authorAvatar: 'https://bit.ly/dan-abramov',
      authorRank: UserRank.GOAL_ACHIEVER,
      isLiked: true,
      likesCount: 31,
      commentsCount: 15,
      category: 'Образование'
    },
    {
      id: '6',
      title: 'Марафон',
      description: 'Пробежать полный марафон. Тренируюсь каждый день, постепенно увеличивая дистанцию. Цель - марафон в следующем году.',
      status: DreamStatus.IN_PROGRESS,
      progress: 65,
      createdAt: new Date('2024-02-15'),
      updatedAt: new Date('2024-03-20'),
      userId: '1',
      isPublic: true,
      image: 'https://images.unsplash.com/photo-1552674605-db6ffd4facb5?w=800&h=600&fit=crop',
      authorName: 'Александр Иванов',
      authorAvatar: 'https://bit.ly/dan-abramov',
      authorRank: UserRank.GOAL_ACHIEVER,
      isLiked: true,
      likesCount: 42,
      commentsCount: 8,
      category: 'Спорт'
    }
  ];

  const handleStatusChange = (dreamId: string, newStatus: DreamStatus) => {
    // Здесь будет логика обновления статуса мечты через API
    console.log(`Обновление статуса мечты ${dreamId} на ${newStatus}`);
  };

  const handleLike = (dreamId: string) => {
    // Здесь будет логика лайка мечты через API
    console.log(`Лайк мечты ${dreamId}`);
  };

  const handleComment = (dreamId: string) => {
    // Здесь будет логика открытия комментариев
    console.log(`Открытие комментариев мечты ${dreamId}`);
  };

  const handleBookmark = (dreamId: string) => {
    setBookmarkedDreams(prev => {
      const isBookmarked = prev.includes(dreamId);
      const newBookmarks = isBookmarked
        ? prev.filter(id => id !== dreamId)
        : [...prev, dreamId];
      
      toast({
        title: isBookmarked ? 'Удалено из закладок' : 'Добавлено в закладки',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      
      return newBookmarks;
    });
  };

  const handleShare = (dream: Dream) => {
    toast({
      title: 'Поделиться мечтой',
      description: `${dream.title}`,
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleCreateDream = (dream: {
    title: string;
    description: string;
    category: string;
    subcategory: string;
  }) => {
    // Здесь будет логика создания мечты
    console.log('Creating dream:', dream);
    onClose();
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Box mb={8}>
        <Heading mb={6}>Мои мечты</Heading>
        <Button
          leftIcon={<RiAddLine />}
          colorScheme="brand"
          onClick={onOpen}
        >
          Новая мечта
        </Button>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {mockMyDreams.map((dream) => (
          <DreamCard
            key={dream.id}
            dream={{
              ...dream,
              isBookmarked: bookmarkedDreams.includes(dream.id)
            }}
            onStatusChange={(status) => handleStatusChange(dream.id, status)}
            onLike={() => handleLike(dream.id)}
            onComment={() => handleComment(dream.id)}
            onBookmark={() => handleBookmark(dream.id)}
            onShare={() => handleShare(dream)}
          />
        ))}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>Создать новую мечту</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <DreamForm onSubmit={handleCreateDream} isLoading={false} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default MyDreams; 
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  HStack,
  Icon,
  Button,
  useColorModeValue,
  Avatar,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import {
  RiLightbulbLine,
  RiHeartLine,
  RiStarLine,
  RiUserHeartLine,
  RiTimeLine,
  RiGroupLine,
  RiRocketLine,
} from 'react-icons/ri';
import Auth from './Auth';
import { Link as RouterLink } from 'react-router-dom';

// Временные данные для демонстрации
const featuredDreams = [
  {
    id: 1,
    title: 'Открыть свою кофейню',
    author: 'Анна М.',
    avatar: 'https://source.unsplash.com/random/100x100/?woman',
    likes: 42,
    category: 'Бизнес',
    timeAgo: '2 часа назад',
  },
  {
    id: 2,
    title: 'Путешествие в Японию',
    author: 'Михаил К.',
    avatar: 'https://source.unsplash.com/random/100x100/?man',
    likes: 38,
    category: 'Путешествия',
    timeAgo: '4 часа назад',
  },
  {
    id: 3,
    title: 'Написать книгу',
    author: 'Елена С.',
    avatar: 'https://source.unsplash.com/random/100x100/?girl',
    likes: 56,
    category: 'Творчество',
    timeAgo: '6 часов назад',
  },
];

const features = [
  {
    icon: RiLightbulbLine,
    title: 'Делитесь мечтами',
    description: 'Публикуйте свои мечты и вдохновляйте других',
  },
  {
    icon: RiHeartLine,
    title: 'Поддерживайте',
    description: 'Поддерживайте мечты других и получайте поддержку',
  },
  {
    icon: RiStarLine,
    title: 'Достигайте целей',
    description: 'Отмечайте прогресс и празднуйте достижения',
  },
];

const Home = () => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const statsBg = useColorModeValue('brand.50', 'gray.700');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgGradient = useColorModeValue(
    'linear(to-b, brand.50, white)',
    'linear(to-b, gray.900, gray.800)'
  );

  return (
    <Box bgGradient={bgGradient} minH="100vh">
      <Container maxW="container.xl" py={20}>
        <VStack spacing={16} align="stretch">
          {/* Первая секция */}
          <VStack spacing={6} textAlign="center">
            <Heading
              as="h1"
              size="2xl"
              bgGradient="linear(to-r, brand.400, brand.600)"
              bgClip="text"
              lineHeight="1.2"
            >
              Добро пожаловать в Пространство Мечты!
            </Heading>
            <Text fontSize="xl" maxW="800px">
              🌟 One Dream — это место, где каждая мечта обретает форму и становится шагом к реальности. 
              Делитесь своими мечтами, вдохновляйтесь историями других и идите к своим целям вместе с единомышленниками.
            </Text>
            <Button
              size="lg"
              colorScheme="brand"
              leftIcon={<Icon as={RiStarLine} />}
              onClick={onOpen}
            >
              Начать мечтать
            </Button>

            {/* Статистика */}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} pt={8} w="full">
              <VStack
                p={6}
                bg={useColorModeValue('white', 'gray.800')}
                rounded="xl"
                shadow="lg"
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)' }}
              >
                <Icon as={RiGroupLine} boxSize={10} color="brand.500" />
                <Text fontSize="4xl" fontWeight="bold" color="brand.500">12,458</Text>
                <Text>Мечтателей уже с нами</Text>
              </VStack>

              <VStack
                p={6}
                bg={useColorModeValue('white', 'gray.800')}
                rounded="xl"
                shadow="lg"
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)' }}
              >
                <Icon as={RiStarLine} boxSize={10} color="brand.500" />
                <Text fontSize="4xl" fontWeight="bold" color="brand.500">45,789</Text>
                <Text>Мечт загружено</Text>
              </VStack>

              <VStack
                p={6}
                bg={useColorModeValue('white', 'gray.800')}
                rounded="xl"
                shadow="lg"
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)' }}
              >
                <Icon as={RiRocketLine} boxSize={10} color="brand.500" />
                <Text fontSize="4xl" fontWeight="bold" color="brand.500">8,932</Text>
                <Text>Мечт исполнено</Text>
              </VStack>
            </SimpleGrid>
          </VStack>

          {/* Вторая секция */}
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            <VStack
              p={8}
              bg={useColorModeValue('white', 'gray.800')}
              rounded="xl"
              shadow="lg"
              spacing={4}
              align="flex-start"
            >
              <Icon as={RiStarLine} boxSize={8} color="brand.400" />
              <Heading as="h2" size="lg">
                Присоединяйтесь к Вселенной Желаний!
              </Heading>
              <Text>
                ✨ Здесь каждый может смело заявить о своей мечте, наблюдать за ее воплощением 
                и поддерживать других на их пути. Ваша мечта — это не просто желание, а энергия, 
                способная изменить мир. Давайте создавать его вместе!
              </Text>
            </VStack>

            <VStack
              p={8}
              bg={useColorModeValue('white', 'gray.800')}
              rounded="xl"
              shadow="lg"
              spacing={4}
              align="flex-start"
            >
              <Icon as={RiHeartLine} boxSize={8} color="brand.400" />
              <Heading as="h2" size="lg">
                Воплотите свою мечту в реальность!
              </Heading>
              <Text>
                🚀 Записывайте свои мечты, наполняйтесь вдохновением от достижений других 
                и двигайтесь вперед с поддержкой единомышленников. One Dream — это не просто 
                платформа, это сообщество людей, готовых менять свою жизнь и мир вокруг.
              </Text>
            </VStack>

            <VStack
              p={8}
              bg={useColorModeValue('white', 'gray.800')}
              rounded="xl"
              shadow="lg"
              spacing={4}
              align="flex-start"
            >
              <Icon as={RiRocketLine} boxSize={8} color="brand.400" />
              <Heading as="h2" size="lg">
                Развивайтесь вместе с нами
              </Heading>
              <Text>
                💫 Каждая достигнутая мечта — это шаг к новым высотам. Отслеживайте свой прогресс, 
                получайте поддержку сообщества и становитесь источником вдохновения для других. 
                Ваш путь к мечте начинается здесь!
              </Text>
            </VStack>
          </SimpleGrid>

          {/* Призыв к действию */}
          <VStack spacing={6} textAlign="center">
            <Heading as="h2" size="xl">
              Готовы начать свой путь к мечте?
            </Heading>
            <Button
              size="lg"
              colorScheme="brand"
              leftIcon={<Icon as={RiRocketLine} />}
              onClick={onOpen}
            >
              Присоединиться сейчас
            </Button>
          </VStack>
        </VStack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="500px">
          <Auth isModal onClose={onClose} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Home; 
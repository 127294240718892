import React from 'react';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoginForm } from './components/Auth/LoginForm';
import { RegisterForm } from './components/Auth/RegisterForm';
import { AuthProvider } from './contexts/AuthContext';
import Home from './pages/Home';
import Dreams from './pages/Dreams';
import MyDreams from './pages/MyDreams';
import Consciousness from './pages/Consciousness';
import Messages from './pages/Messages';
import Community from './pages/Community';
import ProfilePage from './pages/ProfilePage';
import Sidebar from './components/Sidebar/Sidebar';
import theme from './theme';

export function App() {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <Flex minH="100vh">
            <Sidebar />
            <Box flex="1" ml="240px">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/register" element={<RegisterForm />} />
                <Route path="/dreams" element={<Dreams />} />
                <Route path="/my-dreams" element={<MyDreams />} />
                <Route path="/consciousness" element={<Consciousness />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/community" element={<Community />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Routes>
            </Box>
          </Flex>
        </AuthProvider>
      </ChakraProvider>
    </Router>
  );
} 